import { useMemo } from 'react';
import { LineChartPoint } from '../interfaces';
interface ChartTootipProps {
  point?: LineChartPoint;
  xName: string;
  yName: string;
}

const ChartTooltipContent = ({ point, xName, yName }: ChartTootipProps) => {
  const entityName = useMemo(() => {
    if (!point) {
      return '';
    }

    if (point.competition_name) {
      return point.competition_name;
    } else if (point.player_name) {
      return point.player_name;
    } else if (point.team_name) {
      return point.team_name;
    } else {
      return '';
    }
  }, [point]);

  if (!point) {
    return null;
  }

  return (
    <>
      <span>{entityName}</span>
      <span>
        {xName}:{' '}
        {typeof point.x_value === 'number' || typeof point.x_value === 'string'
          ? point.x_value
          : point.x_value.toLocaleDateString()}
      </span>
      <span>
        {yName}: {Number(point.y_value.toFixed(2))}
      </span>
    </>
  );
};

export default ChartTooltipContent;
