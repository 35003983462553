import { memo } from 'react';
import { getDefaultColor } from '../../../../helpers';
import PlayerHeader from '../Player/PlayerHeader';
import { PlayerGeneralData, ReportTypeHeader } from '../interfaces';

interface PlayerComparisonHeaderProps extends ReportTypeHeader {
  player1: PlayerGeneralData;
  player2: PlayerGeneralData;
}

function PlayerComparisonHeader({
  player1,
  player2,
  textSize = 'sm',
  iconSize = 'md',
  narrower = false
}: PlayerComparisonHeaderProps) {
  player1.team_color ??= getDefaultColor(0);
  player2.team_color ??= getDefaultColor(1);
  return (
    <div className="grid grid-cols-2 gap-3">
      <PlayerHeader player={player1} textSize={textSize} iconSize={iconSize} narrower={narrower} />
      <PlayerHeader player={player2} textSize={textSize} iconSize={iconSize} narrower={narrower} />
    </div>
  );
}

export default memo(PlayerComparisonHeader);
