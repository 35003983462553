import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useMemo } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { TeamsBySeason, ValueOption } from 'utils/interfaces';
import { metricToMetricOption, seasonToValueOption, stringToValueOption, teamToValueOption } from 'utils/mappings';
import { getMetricOptionsFromSeasons, getTeamsFromSeason } from '../../helpers';
import { ElementFormComponentProps, LineChartElementFormValues } from '../interfaces';

const LineChartLeagueForm = ({ control, template, report }: ElementFormComponentProps<LineChartElementFormValues>) => {
  const formState = useFormState({ control });
  const timeScale = useWatch({ control: control, name: 'time_scale' });
  const type = useWatch({ control: control, name: 'type' });
  const seasons = useWatch({ control: control, name: 'seasons' });

  const metricsSeasonMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics_within_season_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const metricsSeasonSeasonOptions = useMemo(() => {
    const baseMetrics = template.attribute_choices!.metrics_within_season_season;
    return getMetricOptionsFromSeasons(seasons, baseMetrics);
  }, [template.attribute_choices, seasons]);

  const currentMetrics = useMemo(() => {
    let activeMetric = '';
    let options: ValueOption[] = [];
    switch (timeScale?.id) {
      case 'By match':
      case 'By match (last 10)':
        activeMetric = 'metrics_within_season_match';
        options = metricsSeasonMatchOptions;
        break;
      case 'By season':
        activeMetric = 'metrics_within_season_season';
        options = metricsSeasonSeasonOptions;
        break;
    }
    return { currentMetric: activeMetric, currentOptions: options };
  }, [metricsSeasonMatchOptions, metricsSeasonSeasonOptions, timeScale]);

  const xAxisOptions = useMemo(() => {
    return template.attribute_choices!.time_scale.map(stringToValueOption) || [];
  }, [template.attribute_choices]);

  const seasonOptions: ValueOption[] = useMemo(() => {
    return template.attribute_choices!.seasons.map(seasonToValueOption);
    switch (currentMetrics.currentMetric) {
      case 'metrics_within_season_match':
        break;
      case 'metrics_within_season_season':
        break;
    }
  }, [template.attribute_choices, currentMetrics]);

  const teamOptions: ValueOption[] = useMemo(() => {
    const teamsBySeason: TeamsBySeason[] = template.attribute_choices!.team_by_season ?? [];
    const activeSeasons = seasons.map((season) => season.id) as string[];
    const teams = getTeamsFromSeason(teamsBySeason, activeSeasons) ?? [];
    return teams.map(teamToValueOption);
  }, [template.attribute_choices, seasons]);

  return (
    <>
      <div className="flex gap-6">
        <SelectInput
          formProps={{
            control: control,
            name: 'time_scale',
            rules: { required: 'Time scale is required!' }
          }}
          label={'X - Axis'}
          placeholder="Time scale"
          options={xAxisOptions}
          error={formState.errors.time_scale}
        />

        <SelectInput
          formProps={{
            control: control,
            name: currentMetrics.currentMetric,
            rules: { required: 'Metric is required!' }
          }}
          label={'Y - axis'}
          placeholder="Metric"
          options={currentMetrics.currentOptions}
          error={formState.errors[currentMetrics.currentMetric]}
        />
      </div>

      <SelectInput
        formProps={{
          control: control,
          name: 'seasons'
        }}
        multiple
        label={'Seasons'}
        placeholder="All"
        options={seasonOptions}
      />
      {/* Select team */}
      <SelectInput
        formProps={{
          control: control,
          name: 'teams'
        }}
        multiple
        label={'Teams'}
        options={teamOptions}
        error={formState.errors.teams}
      />
    </>
  );
};

export default LineChartLeagueForm;
