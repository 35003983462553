import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalEditable } from '@lexical/react/useLexicalEditable';
import Save from 'assets/check.svg?react';
import Cancel from 'assets/close-circle.svg?react';
import Edit from 'assets/edit.svg?react';
import { LexicalEditor } from 'lexical';

interface EditablePluginProps {
  onCancelChanges: (editor: LexicalEditor) => void;
  onSaveChanges: (editor: LexicalEditor) => void;
}

const EditablePlugin = ({ onSaveChanges, onCancelChanges }: EditablePluginProps) => {
  const [editor] = useLexicalComposerContext();
  const isEditable = useLexicalEditable();

  function handleEdit() {
    editor.setEditable(true);
  }

  function finalizeEditSession() {
    editor.setEditable(false);
  }

  function handleCancelChanges() {
    onCancelChanges(editor);
    finalizeEditSession();
  }

  function handleSaveChanges() {
    onSaveChanges(editor);
    finalizeEditSession();
  }

  return (
    <div className="flex gap-2">
      {isEditable ? (
        <div className="flex items-center gap-4">
          <button className="group flex items-center gap-2 disabled:cursor-not-allowed" onClick={handleSaveChanges}>
            <span className="text-xs font-medium text-brand-800 group-disabled:text-brand-500">Save</span>
            <Save className="size-4 fill-brand-800 group-disabled:fill-brand-500" />
          </button>

          <button className="group flex items-center gap-2 disabled:cursor-not-allowed" onClick={handleCancelChanges}>
            <span className="text-xs font-medium text-brand-800 group-disabled:text-brand-500">Cancel</span>
            <Cancel className="size-4 fill-brand-800 group-disabled:fill-brand-500" />
          </button>
        </div>
      ) : (
        <button className="group flex items-center gap-2 disabled:cursor-not-allowed" onClick={handleEdit}>
          <span className="text-xs font-medium text-brand-800 group-disabled:text-brand-500">Edit</span>
          <Edit className="size-4 fill-brand-800 group-disabled:fill-brand-500" />
        </button>
      )}
    </div>
  );
};

export default EditablePlugin;
