import { useQueryClient } from '@tanstack/react-query';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions, seasonOptions } from 'constants/generated';
import useActiveProject from 'contexts/project/projectContext';
import useIsMobile from 'hooks/useIsMobile';
import { useObjectsOptions } from 'hooks/useOptions';
import { useGetDatasourceDatasourceIdProjectCompetitions } from 'lib/datasource/datasource';
import { DatasourceSchema, SubscriptionGetSchema, SubscriptionTierSchema } from 'lib/model';
import ErrorMessage from 'modules/common/Form/ErrorMessage';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { FormEventHandler, memo } from 'react';
import { Control, useFormState } from 'react-hook-form';
import { twJoin } from 'tailwind-merge';
import { ConfigurationFormValues, ValueOption } from 'utils/interfaces';

interface DatasourceConfigurationFormProps {
  dataSource: DatasourceSchema;
  control: Control<ConfigurationFormValues>;
  handleSubmit: FormEventHandler<HTMLFormElement>;
}

const DatasourceConfigurationForm = ({ dataSource, control, handleSubmit }: DatasourceConfigurationFormProps) => {
  const isMobile = useIsMobile();
  const queryClient = useQueryClient();
  const { project } = useActiveProject();

  const { data: datasourceCompetitions, isFetching: isFetchingCompetitions } =
    useGetDatasourceDatasourceIdProjectCompetitions(
      dataSource.id!,
      {
        project_id: project.id
      },
      {
        query: {
          queryKey: ['project', project.id, 'dataSource', dataSource.id, 'competitions'],
          enabled: !!dataSource.id && !!project.id
        }
      }
    );

  const activeSubscriptionTier = queryClient.getQueryData<SubscriptionGetSchema>([
    'project',
    project.id!,
    'subscription'
  ]);
  const subscriptionTier = queryClient.getQueryData<Required<SubscriptionTierSchema>>([
    'subscription',
    activeSubscriptionTier!.subscription_tier
  ])!;

  const leagueOptions = useObjectsOptions({ objects: datasourceCompetitions?.objects });
  const areaOptions = useObjectsOptions({ objects: dataSource.datasource_areas });
  const showSeasons =
    (dataSource.datasource_areas && dataSource.datasource_areas.length > 0) ||
    (leagueOptions && leagueOptions.length > 0);

  const formState = useFormState({ control });

  return (
    <form onSubmit={handleSubmit} className="flex flex-col gap-6" id="data-source-configuration-form">
      {dataSource.datasource_areas && dataSource.datasource_areas.length > 0 && (
        <div>
          <SelectInput
            formProps={{
              control: control,
              name: 'areas',
              rules: {
                validate: {
                  minLength: (values: ValueOption[]) => values.length > 0 || `Please select at least one area.`,
                  maxLength: (values: ValueOption[]) =>
                    values.length <= subscriptionTier.max_competitions ||
                    `Your subscription allows you to select at most ${subscriptionTier.max_competitions} areas.`
                }
              }
            }}
            multiple
            label="Areas"
            placeholder="Select areas"
            searchable={true}
            options={areaOptions}
            error={formState.errors.areas}
          />
          <div className="mt-3 text-sm italic text-gray-400">
            Note: Your account may not have access to all of the listed areas.
          </div>
        </div>
      )}
      <div>
        <SelectInput
          formProps={{
            control: control,
            name: 'leagues',
            rules: {
              validate: {
                minLength: (values: ValueOption[]) =>
                  leagueOptions.length === 0 || values.length > 0 || `Please select at least one competition.`,
                maxLength: (values: ValueOption[]) =>
                  values.length <= subscriptionTier.max_competitions ||
                  `Your subscription allows you to select at most ${subscriptionTier.max_competitions} competitions.`
              }
            }
          }}
          multiple
          loading={isFetchingCompetitions}
          disabled={isFetchingCompetitions || datasourceCompetitions?.objects?.length === 0}
          label="Competitions"
          placeholder="Select competitions"
          searchable={true}
          options={leagueOptions}
          error={formState.errors.leagues}
        />

        <div className="mt-3 text-sm italic text-gray-400">
          Note: Your account may not have access to all of the listed competitions.
        </div>
      </div>
      {showSeasons && (
        <SelectInput
          formProps={{
            control: control,
            name: 'min_season',
            rules: {
              required: 'Please select a start season.',
              validate: {
                minSeason: (value: ValueOption) => {
                  const minSeason = subscriptionTier.min_start_season;
                  const startSeason = value.id as number;
                  return (
                    startSeason >= minSeason ||
                    `The earliest start season for your subscription is ${minSeason}/${minSeason + 1}.`
                  );
                }
              }
            }
          }}
          error={formState.errors.min_season}
          label="Start Season"
          placeholder="Select start season"
          options={seasonOptions}
        />
      )}

      <div>
        <div className={twJoin('flex gap-6', isMobile && 'flex-col')}>
          <SelectInput
            formProps={{
              control: control,
              name: 'refreshFrequency',
              rules: {
                validate: {
                  frequency: (value: ValueOption) => {
                    const frequencyLabel = (value.label as string).toLowerCase();
                    switch (subscriptionTier.max_pipeline_frequency) {
                      case 'daily':
                        return true;
                      case 'weekly':
                        // weekly or monthly or yearly
                        if (frequencyLabel.includes('weekly')) {
                          return true;
                        }
                      // fallthrough
                      case 'monthly':
                        // monthly or yearly
                        if (frequencyLabel.includes('monthly')) {
                          return true;
                        }
                      // fallthrough
                      case 'yearly':
                        // yearly
                        if (frequencyLabel.includes('yearly')) {
                          return true;
                        }
                      // fallthrough
                      default:
                        return `Your subscription does not allow you to refresh data more frequently than ${subscriptionTier.max_pipeline_frequency}.`;
                    }
                  }
                }
              }
            }}
            searchable={false}
            placeholder="Default"
            label="Refresh Frequency"
            options={dataSourceRefreshFrequencyOptions}
          />
          <SelectInput
            formProps={{
              control: control,
              name: 'time'
            }}
            searchable={false}
            placeholder="Default"
            label="Time"
            options={CRONTimeOptions}
          />
        </div>
        <ErrorMessage error={formState.errors.refreshFrequency} />

        <div className="mt-1 text-sm font-bold italic text-gray-600">
          Note: Changing the refresh frequency will change it for all data sources.
        </div>
        <div className="mt-3 text-sm italic text-gray-400">
          Note: Currently it's not possible to load data more frequently than weekly. All your datasources should have
          the iteration start setup at the same time.
        </div>
      </div>
    </form>
  );
};

export default memo(DatasourceConfigurationForm);
