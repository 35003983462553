import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { EditorRefPlugin } from '@lexical/react/LexicalEditorRefPlugin';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { EditorState, LexicalEditor } from 'lexical';
import EditablePlugin from 'modules/common/Editor/plugins/EditablePlugin';
import ContentEditable from 'modules/common/Editor/ui/ContentEditable';

interface EditorProps {
  editorRef?: React.MutableRefObject<LexicalEditor | null>;
  title?: string | JSX.Element;
  canEdit?: boolean;
  onChange?: (editorState: EditorState, editor: LexicalEditor, tags: Set<string>) => void;
  editablePluginProps?: {
    onSaveChanges: (editor: LexicalEditor) => void;
    onCancelChanges: (editor: LexicalEditor) => void;
  };
}

const ElementNoteEditor = ({ editorRef, title, canEdit, onChange, editablePluginProps }: EditorProps) => {
  return (
    <div className="relative flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <div>{title}</div>
        {canEdit && editablePluginProps && (
          <EditablePlugin
            onCancelChanges={editablePluginProps.onCancelChanges}
            onSaveChanges={editablePluginProps.onSaveChanges}
          />
        )}
      </div>
      <RichTextPlugin
        contentEditable={
          <div className="relative">
            <ContentEditable placeholder="Enter some text" />
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <ClearEditorPlugin />
      {editorRef && <EditorRefPlugin editorRef={editorRef} />}
      {onChange && <OnChangePlugin onChange={onChange} />}

      <ListPlugin />
      <TabIndentationPlugin />
      <HistoryPlugin />
    </div>
  );
};

export default ElementNoteEditor;
