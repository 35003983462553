import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { MetricOption, ValueOption } from 'utils/interfaces';
import { metricToMetricOption, stringToValueOption } from 'utils/mappings';
import { showAggregateOptions } from '../../helpers';
import AggregateOptions from '../AggregateOptions';
import { loadAttributeValues } from '../helpers';
import { ElementFormProps, TableElementFormValues } from '../interfaces';
import TableLeagueForm from './TableLeagueForm';

const TableElementForm = memo(function TableElementForm({ template, report, onSubmitForm, element }: ElementFormProps) {
  const defaultValues: Partial<TableElementFormValues> = {
    attribute_type: null,
    group_by: [],
    players: [],
    metrics_players_match: [],
    metrics_players_season: [],
    metrics_teams_match: [],
    metrics_teams_season: [],
    seasons: [],
    matches: [],
    competitions: []
  };
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  } else {
    if (report.report_type !== 'match') {
      defaultValues.group_by = [
        {
          label: 'Season',
          id: 'season'
        },
        {
          label: 'Competition',
          id: 'competition'
        }
      ];
    }
    switch (report.report_type) {
      case 'player':
      case 'player_comparison':
      case 'scout':
        defaultValues.attribute_type = {
          label: 'Player',
          id: 'player'
        };
        break;
      case 'team':
      case 'team_comparison':
      case 'league':
        defaultValues.attribute_type = {
          label: 'Team',
          id: 'team'
        };
        break;
    }
  }

  const tableForm = useForm<TableElementFormValues>({
    defaultValues
  });
  const { watch, setValue, control, formState, handleSubmit } = tableForm;
  const attributeType = watch('attribute_type');
  const groupBy = watch('group_by');
  const playerMetrics = watch('metrics_players_match');
  const teamMetrics = watch('metrics_teams_match');

  const addElement = useCallback(
    function addElement(data: TableElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  // Resetting values on change
  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('players', []);
      setValue('metrics_players_match', []);
    } else {
      setValue('metrics_teams_match', []);
    }
  }, [attributeType, groupBy, setValue]);

  // Get metric options from metric seasons and active seasons
  const metricsTeamsMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics_teams_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const metricsPlayersMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics_players_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const active_metrics: MetricOption[] = useMemo(() => {
    if (attributeType?.id === 'team') {
      return teamMetrics;
    } else {
      return playerMetrics;
    }
  }, [attributeType, playerMetrics, teamMetrics]);

  const renderMetrics = useCallback(() => {
    let active_metric = '';
    let metric_options: ValueOption[] = [];
    switch (attributeType?.id) {
      case 'team':
        active_metric = 'metrics_teams_match';
        metric_options = metricsTeamsMatchOptions;
        break;
      case 'player':
        active_metric = 'metrics_players_match';
        metric_options = metricsPlayersMatchOptions;
        break;
      default:
        return (
          <SelectInput
            formProps={{
              control: control,
              name: 'placeholder'
            }}
            multiple
            key={'placeholder'}
            label={'Select aggregation data'}
            disabled={true}
            options={[]}
          />
        );
    }

    return (
      <SelectInput
        formProps={{
          control: control,
          name: active_metric,
          rules: {
            validate: {
              minLength: (values: ValueOption[]) => values.length > 0 || 'At least one metric is required!'
            }
          }
        }}
        multiple
        key={active_metric}
        label={'Metrics'}
        placeholder={'Choose metrics to aggregate'}
        disabled={attributeType === null}
        options={metric_options}
        error={formState.errors[active_metric]}
      />
    );
  }, [attributeType, groupBy, control, formState.errors, metricsPlayersMatchOptions, metricsTeamsMatchOptions]);

  const renderForm = () => {
    switch (report.report_type) {
      case 'league':
        return <TableLeagueForm control={control} template={template} />;
      default:
        return (
          <>
            {report.report_type === 'match' ? (
              <SelectInput
                formProps={{
                  control: control,
                  name: 'attribute_type',
                  rules: { required: 'Main attribute is required!' }
                }}
                disabled={template.attribute_choices!.attribute_type.length === 1}
                label={'Main attribute'}
                options={template.attribute_choices!.attribute_type.map(stringToValueOption)}
                error={formState.errors.attribute_type}
              />
            ) : (
              <>
                <div className="flex gap-6">
                  <SelectInput
                    formProps={{
                      control: control,
                      name: 'attribute_type',
                      rules: { required: 'Main attribute is required!' }
                    }}
                    disabled={template.attribute_choices!.attribute_type.length === 1}
                    label={'Main attribute'}
                    options={template.attribute_choices!.attribute_type.map(stringToValueOption)}
                    error={formState.errors.attribute_type}
                  />
                  <SelectInput
                    formProps={{
                      control: control,
                      name: 'group_by',
                      rules: {
                        validate: {
                          minLength: (values: ValueOption[]) =>
                            values.length > 0 || 'At least one attribute is required!',
                          maxLength: (values: ValueOption[]) =>
                            values.length <= 3 || 'Maximum of three attributes can be selected!'
                        }
                      }
                    }}
                    multiple
                    label={'Breakdown by'}
                    options={template.attribute_choices!.group_by.map(stringToValueOption)}
                    error={formState.errors.group_by}
                  />
                </div>
              </>
            )}
            {renderMetrics()}
            {showAggregateOptions(report) && <AggregateOptions template={template} metrics={active_metrics} />}
          </>
        );
    }
  };

  return (
    <FormProvider {...tableForm}>
      <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
        {renderForm()}
      </form>
    </FormProvider>
  );
});

export default TableElementForm;
