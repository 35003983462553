import { getOpposingTeams } from 'modules/reports/helpers';
import { memo, useCallback, useMemo } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import {
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../../reportCards/interfaces';
import { loadAttributeValues } from '../helpers';
import {
  ElementFormProps,
  HeatMapElementFormValues,
  MatchHeatMapElementFormValues,
  SubjectHeatMapElementFormValues
} from '../interfaces';
import MatchHeatMapElementForm from './MatchHeatMapElementForm';
import SubjectHeatMapElementForm from './components/SubjectHeatMapElementForm';

const HeatMapElementForm = memo(function HeatMapElementForm({
  report,
  template,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<HeatMapElementFormValues> = {};

  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  } else {
    // Load default state
    if (template.name === 'heatmap-event-data') {
      switch (report.report_type) {
        case 'match':
          break;
        default:
          defaultValues.seasons = [];
          defaultValues.competitions = [];
          defaultValues.seasons_comparison = [];
          defaultValues.competitions_comparison = [];
          break;
      }
    }
  }
  const heatMapElementForm = useForm<HeatMapElementFormValues>({
    defaultValues
  });

  const addElement = useCallback(
    function addElement(data: HeatMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );
  const [homeTeam, awayTeam] = getOpposingTeams(report)!;

  const heatMapForm = useMemo(() => {
    let subject1, subject2;
    switch (report.report_type) {
      case 'match':
        return (
          <MatchHeatMapElementForm
            template={template}
            heatMapElementForm={heatMapElementForm as UseFormReturn<MatchHeatMapElementFormValues>}
            homeTeam={homeTeam.team_name}
            awayTeam={awayTeam!.team_name}
          />
        );
      case 'player':
        subject1 = (report.general_data as PlayerGeneralData).player_name;
        break;
      case 'team':
        subject1 = (report.general_data as TeamGeneralData).team_name;
        break;
      case 'player_comparison':
        subject1 = (report.general_data as PlayerComparisonGeneralData).player1.player_name;
        subject2 = (report.general_data as PlayerComparisonGeneralData).player2.player_name;
        break;
      case 'team_comparison':
        subject1 = (report.general_data as TeamComparisonGeneralData).team1.team_name;
        subject2 = (report.general_data as TeamComparisonGeneralData).team2.team_name;
        break;
    }
    return (
      <SubjectHeatMapElementForm
        template={template}
        heatMapElementForm={heatMapElementForm as UseFormReturn<SubjectHeatMapElementFormValues>}
        subject1={subject1!}
        subject2={subject2}
      />
    );
  }, [awayTeam, heatMapElementForm, homeTeam.team_name, report.general_data, report.report_type, template]);

  return (
    <form
      className="flex w-full flex-col gap-10"
      id="new-element-form"
      onSubmit={heatMapElementForm.handleSubmit(addElement)}
    >
      {heatMapForm}
    </form>
  );
});

export default HeatMapElementForm;
