import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useMemo, useState } from 'react';
import PlayerPositionChart from './PlayerPositionChart';
import { MatchTimePoint, PositionMapPlayerData, PositionMapPoint } from './interfaces';
import { MatchInfo } from 'utils/interfaces';
import ElementMatchInfo from '../ElementMatchInfo';
import MatchTimeSlider from './components/MatchTimeSlider';
import { getReportGeneralColor } from 'modules/reports/helpers';
import { getGetReportLastEditedQueryKey, usePutReportReportIdElementElementId } from 'lib/report/report';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';

interface PositionMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

interface PositionMapData {
  'avg-positions-home': PositionMapPoint[];
  'avg-positions-away': PositionMapPoint[];
  match_info: MatchInfo;
}

function reduceTimePoints(times: { [key: number]: MatchTimePoint }, player: PositionMapPlayerData) {
  if (player.minute_subbed_out) {
    const subbedOut = times[player.minute_subbed_out]?.subbed_out ?? [];
    subbedOut.push(player);
    times[player.minute_subbed_out] = {
      ...times[player.minute_subbed_out],
      match_time: Number(player.minute_subbed_out),
      subbed_out: subbedOut
    };
  }
  if (player.minute_subbed_in) {
    const subbedIn = times[player.minute_subbed_in]?.subbed_in ?? [];
    subbedIn.push(player);
    times[player.minute_subbed_in] = {
      ...times[player.minute_subbed_in],
      match_time: Number(player.minute_subbed_in),
      subbed_in: subbedIn
    };
  }
  return times;
}

function filterCurrentPlayers(player: PositionMapPoint, match_time: number) {
  if (player.player_started) {
    // Player started the match and was not subbed out
    return (player.minute_subbed_out && player.minute_subbed_out > match_time) || !player.minute_subbed_out;
  } else if (player.minute_subbed_in && player.minute_subbed_out) {
    // Player was subbed in and out
    return player.minute_subbed_in <= match_time && player.minute_subbed_out > match_time;
  } else {
    // Player was subbed in
    return player.minute_subbed_in && player.minute_subbed_in <= match_time;
  }
}

const PositionMapElement = memo(function PositionMapElement({ element, report }: PositionMapElementProps) {
  const queryClient = useQueryClient();
  const [currentTime, setCurrentTime] = useState<number>(element.attribute_values?.match_time[0] ?? 0);
  const data = element.entity_data as PositionMapData;
  const match = data.match_info;

  const { allHomePlayers, allAwayPlayers } = useMemo(() => {
    const allHomePlayers = (data['avg-positions-home'] ?? []).map((player) => {
      return { ...player, team_color: player.team_color ?? getReportGeneralColor(report, 0, 'home') };
    });
    const allAwayPlayers = (data['avg-positions-away'] ?? []).map((player) => {
      return { ...player, team_color: player.team_color ?? getReportGeneralColor(report, 0, 'away') };
    });

    return { allHomePlayers, allAwayPlayers };
  }, [element.entity_data]);

  const matchTimePoints = useMemo(() => {
    const times: { [key: number]: MatchTimePoint } = {};

    allHomePlayers.reduce(reduceTimePoints, times);
    allAwayPlayers.reduce(reduceTimePoints, times);

    return times;
  }, [allHomePlayers, allAwayPlayers]);

  const awayPlayers = useMemo(() => {
    return allAwayPlayers.filter((player) => filterCurrentPlayers(player, currentTime));
  }, [allAwayPlayers, currentTime]);

  const homePlayers = useMemo(() => {
    return allHomePlayers.filter((player) => filterCurrentPlayers(player, currentTime));
  }, [allHomePlayers, currentTime]);

  const { mutate: editElement } = usePutReportReportIdElementElementId({
    mutation: {
      onMutate: async ({ data }) => {
        const loadingToastId = toast.info('Saving changes...', { autoClose: false });
        setCurrentTime(data.attribute_values?.match_time[0] ?? 0);
        return { loadingToastId };
      },
      onError: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        if (err instanceof AxiosError) {
          toast.error(err.response?.data.error);
        }
        toast.error('Your have unsaved changes');
      },
      onSuccess: (err, res, context) => {
        toast.dismiss(context?.loadingToastId);
        toast.success('Your changes have been saved');
        queryClient.setQueryData(['reports', report.id!, 'elements', element.id], (oldData: ReportElementSchema) => {
          return {
            ...oldData,
            attribute_values: {
              ...oldData.attribute_values,
              match_time: res.data.attribute_values?.match_time
            }
          };
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          exact: true,
          queryKey: getGetReportLastEditedQueryKey()
        });
      }
    }
  });

  function onChangeTime(time: number) {
    editElement({
      reportId: report.id!,
      elementId: element.id!,
      data: {
        report_element_template: element.report_element_template!,
        position: element.position,
        attribute_values: { ...element.attribute_values, match_time: [time] }
      }
    });
  }

  return (
    <div className="flex flex-col gap-6">
      {report.report_type !== 'match' && match && <ElementMatchInfo match={match} element={element} />}
      <MatchTimeSlider timePoints={matchTimePoints} currentTime={currentTime} onChangeTime={onChangeTime} />
      <PlayerPositionChart away={awayPlayers} home={homePlayers} match={match} report={report} />
    </div>
  );
});

export default PositionMapElement;

