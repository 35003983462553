import { useQueryClient } from '@tanstack/react-query';
import Award from 'assets/award.svg?react';
import useAuth from 'contexts/auth/authContext';
import { SubscriptionGetSchema } from 'lib/model';
import Button from 'modules/common/Button';
import { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardSubscriptionDataSourcesCard from './DashboardSubscriptionDataSourcesCard';
import DashboardSubscriptionUsersCard from './DashboardSubscriptionUsersCard';
import { useGetSubscriptionTier } from 'lib/subscription-tier/subscription-tier';
import useActiveProject from 'contexts/project/projectContext';

function DashboardSubscriptionUsage() {
  const { isOwner, isAdmin } = useAuth();
  const navigate = useNavigate();
  const { project } = useActiveProject();
  const queryClient = useQueryClient();

  const { data: subscriptions, isPending: subscriptionsPending } = useGetSubscriptionTier(
    {},
    { query: { queryKey: ['subscriptionTiers'] } }
  );

  const activeTier = queryClient.getQueryData<SubscriptionGetSchema>(['project', project.id!, 'subscription']);
  function goToBilling() {
    navigate('/settings/billing');
  }

  function goToTierPlans() {
    navigate('/settings/tier-plans');
  }

  if (!isOwner && !isAdmin) {
    return null;
  }
  if (subscriptionsPending) {
    return <></>;
  }
  const paymentDate = activeTier?.subscription_end
    ? new Date(activeTier.subscription_end!).toLocaleDateString()
    : 'N/A';
  const currentSubscriptionTier = subscriptions!.objects!.find((x) => x.id === activeTier?.subscription_tier)!;

  return (
    <div className="flex flex-col gap-6">
      <div className="flex items-center justify-between gap-6">
        <div className="flex items-center gap-3">
          <div className="flex items-center gap-3">
            <Award className="size-5 fill-gray-700" />
            <span className="text-sm font-semibold">{currentSubscriptionTier?.name}</span>
          </div>
          <div className="flex gap-2">
            <span className="text-sm font-medium">Next Invoice Issue date:</span>
            <span className="text-sm font-medium">{paymentDate}</span>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <Button isFullWidth={false} variant="secondary" size="sm" onClick={goToBilling}>
            Invoices
          </Button>
          <Button isFullWidth={false} variant="secondary" size="sm" onClick={goToTierPlans}>
            Upgrade Plan
          </Button>
        </div>
      </div>
      <div className="flex flex-wrap gap-6">
        <DashboardSubscriptionDataSourcesCard />
        <DashboardSubscriptionUsersCard />
      </div>
    </div>
  );
}

export default memo(DashboardSubscriptionUsage);
