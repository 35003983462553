/* eslint-disable react/jsx-no-bind */
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo, useMemo } from 'react';
import { snakeCaseToWords } from 'utils/helpers';
import { LineChartElementFormValues } from '../../dialogs/elementForms/interfaces';
import { AttributeValues } from '../interfaces';
import LineChart from './LineChart';
import LineChartInfo from './LineChartInfo';
import { LineChartPoint } from './interfaces';

interface LineChartElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const LineChartElement = memo(function LineChartElement({ report, element }: LineChartElementProps) {
  const events = element.entity_data?.events as LineChartPoint[];
  const competitionEvents = (element.entity_data?.competition_events ?? []) as LineChartPoint[];

  const attributeValues = element.attribute_values as AttributeValues<LineChartElementFormValues>;
  const timeScale = attributeValues?.time_scale?.[0] ?? 'Match time';
  const type = attributeValues?.type?.[0] ?? 'For specific match';

  const { xName, yName } = useMemo(() => {
    const xNameString = snakeCaseToWords(String(timeScale));
    let yNameString = 'Value';

    if (type === 'For specific match') {
      yNameString = snakeCaseToWords(String(attributeValues.metrics_within_match[0]));
    } else {
      if (timeScale === 'By season') {
        yNameString = snakeCaseToWords(String(attributeValues.metrics_within_season_season[0]));
      } else {
        yNameString = snakeCaseToWords(String(attributeValues.metrics_within_season_match[0]));
      }
    }

    return { xName: xNameString, yName: yNameString };
  }, [attributeValues, timeScale, type]);

  let key: keyof LineChartPoint = 'team_id';
  if (type === 'For specific match') {
    key = 'team_id';
  } else {
    switch (report.report_type) {
      case 'team':
      case 'team_comparison':
      case 'match':
        key = 'team_id';
        break;
      case 'player':
      case 'player_comparison':
      case 'scout':
        key = 'player_id';
        break;
    }
  }

  let xType: 'number' | 'date' | 'category' = 'date';
  if (timeScale === 'By season') {
    xType = 'category';
  } else if (timeScale === 'Match time') {
    xType = 'number';
  }

  return (
    <div className="flex flex-col gap-6">
      <LineChartInfo element={element} report={report} />
      <LineChart
        events={events}
        competitionEvents={competitionEvents}
        groupByKey={key}
        xName={xName}
        yName={yName}
        xType={xType}
        report={report}
      />
    </div>
  );
});

export default LineChartElement;
