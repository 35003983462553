import { useQueryClient } from '@tanstack/react-query';
import CheckCircle from 'assets/check-circle.svg?react';
import Euro from 'assets/euro.svg?react';
import useAuth from 'contexts/auth/authContext';
import { ProjectGetSchema, SubscriptionGetSchema, SubscriptionTierSchema } from 'lib/model';
import {
  useGetSubscriptionTier,
  useGetSubscriptionTierSubscriptionTierIdAddOn
} from 'lib/subscription-tier/subscription-tier';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';
import Button from 'modules/common/Button';
import Divider from 'modules/common/Divider';
import Pill from 'modules/common/Pill';
import TierPlanTitle from 'modules/common/TierPlanTitle';
import { memo, useState } from 'react';
import TierPlanChangeDialog from './dialogs/TierPlanChangeDialog';
import useActiveProject from 'contexts/project/projectContext';

interface TierPlanProps {
  tier: SubscriptionTierSchema;
  selected: boolean;
}

function TierPlan({ tier, selected }: TierPlanProps) {
  const [changeRequestOpen, setChangeRequestOpen] = useState(false);
  const { isOwner, isAdmin } = useAuth();
  if (!isOwner && !isAdmin) {
    throw new Error('403 Forbidden');
  }
  const { data: extraFeatures, isPending } = useGetSubscriptionTierSubscriptionTierIdAddOn(
    tier.id!,
    {},
    { query: { queryKey: [tier.name + 'TierAddOn'] } }
  );

  function openChangeRequest() {
    setChangeRequestOpen(true);
  }

  if (isPending) {
    return <></>;
  }

  // check if price is null
  const price =
    tier.price_monthly === null || tier.price_monthly === undefined || tier.price_monthly === 0
      ? 'Inquire'
      : parseFloat(tier.price_monthly!.toString()).toFixed(2);

  return (
    <div className="flex flex-col gap-6 rounded-xl border border-gray-300">
      <header className="flex flex-wrap items-center gap-3 rounded-t-2xl bg-gray-50 px-6 py-8">
        <TierPlanTitle name={tier.name!} bigger />
        {selected ? (
          <Pill type="empty">Current plan</Pill>
        ) : tier.recommended ? (
          <Pill type="brand">Most popular</Pill>
        ) : (
          <></>
        )}
      </header>
      <div className="flex grow flex-col gap-6 pt-3">
        <div className="flex flex-col gap-3 px-6">
          {tier.features!.map((feature) => (
            <div className="flex items-center gap-2" key={feature}>
              <CheckCircle className="size-4 shrink-0 fill-green-600" />
              <span className="text-xs font-medium">{feature}</span>
            </div>
          ))}
        </div>
        {false && extraFeatures!.objects!.length > 0 && (
          <>
            <Divider />
            <div className="flex flex-col gap-3">
              {extraFeatures!.objects!.map((extraFeature) => (
                <div className="flex items-center gap-2 px-6" key={extraFeature.id}>
                  <Euro className="size-4 shrink-0 fill-green-600" />
                  <span className="text-xs font-medium">{extraFeature.description}</span>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <footer>
        <Divider />
        <div className="flex flex-col gap-6 p-6">
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-2">
              <span className="text-2xl font-bold">{price}</span>
            </div>
          </div>
          {selected ? (
            <Button disabled variant="secondary" size="xl" onClick={openChangeRequest}>
              Current plan
            </Button>
          ) : (
            <Button variant="primary" size="xl" onClick={openChangeRequest}>
              Select Plan
            </Button>
          )}
        </div>
        <TierPlanChangeDialog tierPlan={tier} open={changeRequestOpen} setOpen={setChangeRequestOpen} />
      </footer>
    </div>
  );
}

function TierPlans() {
  const { project } = useActiveProject();
  const { data: tiers, isPending } = useGetSubscriptionTier({}, { query: { queryKey: ['subscriptionTiers'] } });
  const { data: activeTier, isPending: isActiveTierPending } = useGetProjectProjectIdSubscription(
    project!.id!,
    {},
    { query: { queryKey: ['project', project.id!, 'subscription'] } }
  );

  if (isPending || isActiveTierPending) {
    return <></>;
  }

  // TO DO: get best property on return object
  return (
    <section className="grid grid-cols-tier-plans gap-6 p-6">
      {tiers?.objects?.map((tier) => (
        <TierPlan
          key={tier.id}
          selected={tier.id === (activeTier as SubscriptionGetSchema).subscription_tier}
          tier={tier}
        />
      ))}
    </section>
  );
}

export default memo(TierPlans);
