import { memo } from 'react';
import { toShortNumber } from 'utils/helpers';
import ElementRow from '../../elements/ElementRow';
import TeamHeader from './TeamHeader';

import { TeamGeneralData } from '../interfaces';

interface TeamElementProps {
  team: TeamGeneralData;
}

const TeamElement = memo(function TeamElement({ team }: TeamElementProps) {
  return (
    <div className="flex flex-col gap-6">
      <TeamHeader team={team} textSize="lg" iconSize="md" />
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">COUNTRY</span>
          <span>{team.team_country ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">ESTABLISHED YEAR</span>
          <span>{team.established ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">HOME STADIUM</span>
          <span>{team.stadium_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">HEAD COACH</span>
          <span>{team.head_coach ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">DOM COMPETITION NAME</span>
          <span>{team.dom_league_name ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">TOTAL VALUE</span>
          <span>{team.total_value ? toShortNumber(team.total_value) : '-'}</span>
        </ElementRow>
      </div>
    </div>
  );
});

export default TeamElement;
