import { timePeriodOptions } from 'constants/formOptions';
import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import ButtonTabs from 'modules/common/ButtonTabs';
import { getReportGeneralColor } from 'modules/reports/helpers';
import { memo, useState } from 'react';
import { twJoin } from 'tailwind-merge';
import { MatchInfo, Values } from 'utils/interfaces';
import {
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../reportCards/interfaces';
import HeatMapChart from './HeatMapChart';
import HeatMapPlayers from './HeatMapPlayers';
import { HeatMapPlayer, SubjectHeatMapPeriodsData } from './interfaces';
import ElementMatchInfo from '../ElementMatchInfo';
import { capitalizeWord } from 'utils/helpers';

interface HeatMapData {
  heatmap_data_by_period: SubjectHeatMapPeriodsData;
  match_info: MatchInfo | MatchInfo[];
  players: HeatMapPlayer[];
  heatmap_data_by_period_comparison: SubjectHeatMapPeriodsData;
  match_info_comparison: MatchInfo;
  players_comparison: HeatMapPlayer[];
}

interface SubjectHeatMapElementProps {
  element: ReportElementSchema;
  report: ReportNestedSchema;
}

const SubjectHeatMapElement = memo(function SubjectHeatMapElement({ element, report }: SubjectHeatMapElementProps) {
  const [timePeriod, setTimePeriod] = useState<Values<typeof timePeriodOptions>>(timePeriodOptions[3]);
  const {
    heatmap_data_by_period: data,
    match_info: matchInfo,
    heatmap_data_by_period_comparison: comparisonData,
    match_info_comparison: comparisonMatchInfo,
    players_comparison: comparisonPlayers,
    players
  } = element.entity_data as HeatMapData;

  let subject1, subject2, teamId1, teamId2, homeLabel, awayLabel, homeComparisonLabel, awayComparisonLabel;
  switch (report.report_type) {
    case 'player':
      subject1 = (report.general_data as PlayerGeneralData).player_name;
      teamId1 = (report.general_data as PlayerGeneralData).team_id;
      homeLabel = subject1 + `'s team`;
      awayLabel = 'Opponent team';
      homeComparisonLabel = homeLabel;
      awayComparisonLabel = awayLabel;
      break;
    case 'team':
      subject1 = (report.general_data as TeamGeneralData).team_name;
      teamId1 = (report.general_data as TeamGeneralData).team_id;
      homeLabel = subject1;
      awayLabel = 'Opponent team';
      homeComparisonLabel = homeLabel;
      awayComparisonLabel = awayLabel;
      break;
    case 'player_comparison':
      subject1 = (report.general_data as PlayerComparisonGeneralData).player1.player_name;
      teamId1 = (report.general_data as PlayerComparisonGeneralData).player1.team_id;
      subject2 = (report.general_data as PlayerComparisonGeneralData).player2.player_name;
      teamId2 = (report.general_data as PlayerComparisonGeneralData).player2.team_id;
      homeLabel = subject1 + `'s team`;
      awayLabel = 'Opponent team';
      homeComparisonLabel = subject2 + `'s team`;
      awayComparisonLabel = awayLabel;
      break;
    case 'team_comparison':
      subject1 = (report.general_data as TeamComparisonGeneralData).team1.team_name;
      teamId1 = (report.general_data as TeamComparisonGeneralData).team1.team_id;
      subject2 = (report.general_data as TeamComparisonGeneralData).team2.team_name;
      teamId2 = (report.general_data as TeamComparisonGeneralData).team2.team_id;
      homeLabel = subject1;
      awayLabel = 'Opponent team';
      homeComparisonLabel = subject2;
      awayComparisonLabel = awayLabel;
      break;
  }

  return (
    <div className="flex w-full max-w-screen-md flex-col gap-3 self-center">
      <ButtonTabs options={timePeriodOptions} selected={timePeriod} setSelected={setTimePeriod} />
      <div className={twJoin('grid gap-3', !!comparisonData ? 'grid-cols-2' : 'grid-cols-1')}>
        <div className="flex flex-col justify-end gap-3">
          <span className="text-xs font-semibold">{subject1}</span>
          <ElementMatchInfo match={matchInfo} />
          {(element.attribute_values?.event_types || element.report_element_template_name === 'heatmap-event-data') && (
            <div className="text-xs font-semibold">
              Events:{' '}
              {element.attribute_values?.event_types && element.attribute_values.event_types.length > 0
                ? element.attribute_values.event_types.map((type: string) => capitalizeWord(type)).join(', ')
                : 'All'}
            </div>
          )}
        </div>
        {comparisonData && (
          <div className="flex flex-col justify-end gap-3">
            <span className="text-xs font-semibold">{subject2}</span>
            <ElementMatchInfo match={comparisonMatchInfo} />
            {(element.attribute_values?.event_types_comparison ||
              element.report_element_template_name === 'heatmap-event-data') && (
              <div className="text-xs font-semibold">
                Events:{' '}
                {element.attribute_values?.event_types_comparison &&
                element.attribute_values.event_types_comparison.length > 0
                  ? element.attribute_values.event_types_comparison
                      .map((type: string) => capitalizeWord(type))
                      .join(', ')
                  : 'All'}
              </div>
            )}
          </div>
        )}

        <HeatMapChart
          data={data[timePeriod.id]}
          isVertical={!!comparisonData}
          homeTeamLabel={homeLabel}
          awayTeamLabel={awayLabel}
        />
        {comparisonData && (
          <HeatMapChart
            data={comparisonData[timePeriod.id]}
            isVertical={true}
            homeTeamLabel={homeComparisonLabel}
            awayTeamLabel={awayComparisonLabel}
          />
        )}
        <HeatMapPlayers players={players} color={getReportGeneralColor(report, teamId1!)} />
        {comparisonData && (
          <HeatMapPlayers players={comparisonPlayers} color={getReportGeneralColor(report, teamId2!)} />
        )}
      </div>
    </div>
  );
});

export default SubjectHeatMapElement;
