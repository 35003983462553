/* eslint-disable @typescript-eslint/no-explicit-any */
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useEffect, useMemo } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { filterMatchByCompetition, filterMatchBySeason } from 'utils/filters';
import { Competition, MatchDetailed, OptionTypes, Season } from 'utils/interfaces';
import { competitionToValueOption, matchDetailedToValueOption, seasonToValueOption } from 'utils/mappings';

interface FormMatchSelectionProps {
  form: UseFormReturn<any>;
  multiple?: boolean;
  seasonsFormName: string;
  competitionsFormName: string;
  matchesFormName: string;
  allSeasons: Season[];
  allCompetitions: Competition[];
  allMatches: MatchDetailed[];
}
const FormMatchSelection = ({
  form,
  seasonsFormName,
  competitionsFormName,
  matchesFormName,
  allSeasons,
  allCompetitions,
  allMatches,
  multiple
}: FormMatchSelectionProps) => {
  const formState = useFormState(form);

  const seasons = useWatch({
    control: form.control,
    name: seasonsFormName
  }) as OptionTypes;
  const competitions = useWatch({
    control: form.control,
    name: competitionsFormName
  }) as OptionTypes;

  const matchOptions = useMemo(() => {
    return (
      allMatches
        .filter((option: MatchDetailed) => filterMatchBySeason(option, seasons))
        .filter((option: MatchDetailed) => filterMatchByCompetition(option, competitions))
        ?.map(matchDetailedToValueOption) || []
    );
  }, [competitions, seasons, allMatches]);

  // Update active matches based on available options
  useEffect(() => {
    const activeMatches = form.getValues(matchesFormName) as OptionTypes;
    if (activeMatches) {
      if (Array.isArray(activeMatches)) {
        const newMatches = activeMatches.filter((match) => matchOptions.some((option) => option.id === match.id));
        form.setValue(matchesFormName, newMatches);
      } else {
        const newMatches = matchOptions.find((option) => option.id === activeMatches.id);
        form.setValue(matchesFormName, newMatches ?? null);
      }
    }
  }, [matchOptions, form]);

  return (
    <>
      <div className="flex gap-6">
        <SelectInput
          formProps={{
            control: form.control,
            name: seasonsFormName
            // rules: { required: 'Season is required!' }
          }}
          multiple={multiple}
          label="Seasons"
          options={allSeasons.map(seasonToValueOption) || []}
          error={formState.errors.seasons}
        />
        <SelectInput
          formProps={{
            control: form.control,
            name: competitionsFormName
            // rules: { required: 'Competition is required!' }
          }}
          multiple={multiple}
          label="Competitions"
          options={allCompetitions.map(competitionToValueOption) || []}
          error={formState.errors.competitions}
        />
      </div>
      <SelectInput
        formProps={{
          control: form.control,
          name: matchesFormName
          // rules: { required: 'Match is required!' }
        }}
        multiple={multiple}
        label="Matches"
        options={matchOptions}
        // disabled={!seasons || !competitions}
        error={formState.errors.matches}
      />
    </>
  );
};

export default FormMatchSelection;
