export function isEditorStateEmpty(editorState?: string) {
  if (!editorState) {
    return true;
  }

  const content = JSON.parse(editorState);
  if (content && content.root) {
    const root = content.root;
    const children = root.children;
    if (children.length === 0) {
      return true;
    } else if (children.length === 1) {
      const firstChild = children[0];
      if (firstChild.children.length === 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
