import { memo } from 'react';
import PlayerHeader from '../Player/PlayerHeader';

import { getDefaultColor } from '../../../../helpers';
import { ReportTypeHeader, ScoutPlayerData } from '../interfaces';

interface ScoutHeaderProps extends ReportTypeHeader {
  manualPlayers: ScoutPlayerData[];
  filteredPlayers: ScoutPlayerData[];
}

function ScoutHeader({
  manualPlayers,
  filteredPlayers,
  textSize = 'sm',
  iconSize = 'md',
  narrower = false
}: ScoutHeaderProps) {
  const allPlayers = [...manualPlayers, ...filteredPlayers];
  const shownPlayers = allPlayers.slice(0, 3);
  for (let i = 0; i < shownPlayers.length; i++) {
    shownPlayers[i].team_color ??= getDefaultColor(i);
  }
  return (
    <div
      className="grid gap-1.5"
      style={{
        gridTemplateColumns: `repeat(${allPlayers.length > 3 ? 4 : shownPlayers.length}, minmax(0, 1fr))`
      }}
    >
      {shownPlayers.map((player) => (
        <PlayerHeader
          key={player.player_id}
          player={player}
          textSize={textSize}
          iconSize={iconSize}
          narrower={narrower}
        />
      ))}
      {allPlayers.length > 3 && (
        <div className="flex items-center justify-center rounded-xl bg-gray-50 px-1 py-3">
          <span className="text-sm font-semibold">+{allPlayers.length - 3}</span>
        </div>
      )}
    </div>
  );
}

export default memo(ScoutHeader);
