import { ReportNestedSchema, UserAccountGetSchema } from 'lib/model';
import { Team } from 'utils/interfaces';
import {
  LeagueGeneralData,
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  ScoutGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from './reportPage/components/reportCards/interfaces';

export function getOpposingTeams(report: ReportNestedSchema): [Team, Team | null] | null {
  if (report.report_type === 'match') {
    const generalData = report.general_data as MatchGeneralData;
    return [
      { team_id: generalData.home_team_id, team_name: generalData.home_team_name },
      { team_id: generalData.away_team_id, team_name: generalData.away_team_name }
    ];
  } else if (report.report_type === 'player') {
    const generalData = report.general_data as PlayerGeneralData;
    return [{ team_id: generalData.team_id, team_name: generalData.team_name }, null];
  } else if (report.report_type === 'player_comparison') {
    const generalData = report.general_data as PlayerComparisonGeneralData;
    return [
      { team_id: generalData.player1.team_id, team_name: generalData.player1.team_name },
      { team_id: generalData.player2.team_id, team_name: generalData.player2.team_name }
    ];
  } else if (report.report_type === 'team') {
    const generalData = report.general_data as TeamGeneralData;
    return [{ team_id: generalData.team_id, team_name: generalData.team_name }, null];
  } else if (report.report_type === 'team_comparison') {
    const generalData = report.general_data as TeamComparisonGeneralData;
    return [
      { team_id: generalData.team1.team_id, team_name: generalData.team1.team_name },
      { team_id: generalData.team2.team_id, team_name: generalData.team2.team_name }
    ];
  } else return null;
}

/**
 * Converts an HSV color value to RGB.
 *
 * @param h - The hue, a number between 0 and 1.
 * @param s - The saturation, a number between 0 and 1.
 * @param v - The value (brightness), a number between 0 and 1.
 * @returns An object with the RGB representation, with each component (r, g, b) as an integer between 0 and 255.
 */
// Code from https://stackoverflow.com/questions/17242144/javascript-convert-hsb-hsv-color-to-rgb-accurately
function HSVtoRGB(h: number, s: number, v: number) {
  let r, g, b;
  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);
  switch (i % 6) {
    case 0:
      r = v;
      g = t;
      b = p;
      break;
    case 1:
      r = q;
      g = v;
      b = p;
      break;
    case 2:
      r = p;
      g = v;
      b = t;
      break;
    case 3:
      r = p;
      g = q;
      b = v;
      break;
    case 4:
      r = t;
      g = p;
      b = v;
      break;
    case 5:
      r = v;
      g = p;
      b = q;
      break;
  }
  return {
    r: Math.round(r! * 255),
    g: Math.round(g! * 255),
    b: Math.round(b! * 255)
  };
}

export function getDefaultColor(index: number, alpha: number = 1.0) {
  const rgb = HSVtoRGB(index * 0.618033988749895, 0.9, 0.8);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export function getDefaultCompetitionColor(index: number, alpha: number = 1.0) {
  // TODO: Generate colors based on index
  const rgb = HSVtoRGB(0.7, 0.7, 0.6);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
}

export function getDefaultTeamColor(report: ReportNestedSchema, team_id: number) {
  if (report.report_type === 'match') {
    const generalData = report.general_data as MatchGeneralData;
    if (generalData.home_team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'player') {
    const generalData = report.general_data as PlayerGeneralData;
    if (generalData.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'player_comparison') {
    const generalData = report.general_data as PlayerComparisonGeneralData;
    if (generalData.player1.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'team') {
    const generalData = report.general_data as TeamGeneralData;
    if (generalData.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'team_comparison') {
    const generalData = report.general_data as TeamComparisonGeneralData;
    if (generalData.team1.team_id === team_id) {
      return getDefaultColor(0);
    } else {
      return getDefaultColor(1);
    }
  } else if (report.report_type === 'scout') {
    const generalData = report.general_data as ScoutGeneralData;
    if (generalData.players_filtered.length) {
      return getDefaultColor(generalData.players_filtered.findIndex((x) => x.team_id === team_id));
    } else {
      return getDefaultColor(generalData.players_manual.findIndex((x) => x.team_id === team_id));
    }
  }
  return getDefaultColor(0);
}

// HACK : Added player_id so player_comaprison reports can differentiate players
export function getReportGeneralColor(report: ReportNestedSchema, team_id: number, team?: string, player_id?: number) {
  switch (report.report_type) {
    case 'match': {
      const generalData = report.general_data as MatchGeneralData;
      if (team === 'home' || generalData.home_team_id === team_id) {
        return generalData.home_team_color ?? getDefaultColor(0);
      } else {
        return generalData.away_team_color ?? getDefaultColor(1);
      }
    }
    case 'player': {
      const generalData = report.general_data as PlayerGeneralData;
      return generalData.team_color ?? getDefaultColor(0);
    }
    case 'player_comparison': {
      const generalData = report.general_data as PlayerComparisonGeneralData;
      // If both players are on the same team, they will return the same color
      // But to differentiate players in that case more data is needed (player_id)
      if (generalData.player1.player_id === player_id) {
        return generalData.player1.team_color ?? getDefaultColor(0);
      } else {
        return generalData.player2.team_color ?? getDefaultColor(1);
      }
    }
    case 'team': {
      const generalData = report.general_data as TeamGeneralData;
      if (team === 'home' || team === 'away') {
        return generalData.color || getDefaultColor(0);
      }

      if (team_id === null || team_id === generalData.team_id) {
        return generalData.color ?? getDefaultColor(0);
      } else {
        return getDefaultColor(1);
      }
    }
    case 'team_comparison': {
      const generalData = report.general_data as TeamComparisonGeneralData;
      if (team === 'home' || generalData.team1.team_id === team_id) {
        return generalData.team1.color ?? getDefaultColor(0);
      } else {
        return generalData.team2.color ?? getDefaultColor(1);
      }
    }
    case 'scout': {
      const generalData = report.general_data as ScoutGeneralData;
      if (generalData.players_filtered.length) {
        return (
          generalData.players_filtered.find((x) => x.player_id === player_id)?.team_color ??
          getDefaultColor(generalData.players_filtered.findIndex((x) => x.player_id === player_id))
        );
      } else {
        return (
          generalData.players_manual.find((x) => x.player_id === player_id)?.team_color ??
          getDefaultColor(generalData.players_manual.findIndex((x) => x.player_id === player_id))
        );
      }
    }
    case 'league': {
      const general_data = report.general_data as LeagueGeneralData;
      return getDefaultColor(team_id ?? 0);
    }
    default:
      return team === 'home' ? getDefaultColor(0) : getDefaultColor(1);
  }
}

export function canUserEditReport(report: ReportNestedSchema, user: UserAccountGetSchema): boolean {
  const sharedUser = report.users?.find((x) => x.user_id === user.id);
  return sharedUser?.permission === 'edit';
}
