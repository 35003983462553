/* eslint-disable @typescript-eslint/no-explicit-any */
import LinePath, { LinePathProps } from '@visx/shape/lib/shapes/LinePath';
import { curveMonotoneX } from 'd3';
import { isLightColor } from 'utils/helpers';
import { LineChartPoint } from '../interfaces';

interface LineChartProps extends LinePathProps<any> {
  color: string;
  points: LineChartPoint[];
}

const ChartLinePath = ({ points, color, ...rest }: LineChartProps) => {
  return isLightColor(color) ? (
    <>
      <LinePath
        data={points}
        stroke="#9ca3af"
        curve={curveMonotoneX}
        strokeWidth={3}
        shapeRendering="geometricPrecision"
        {...rest}
      />
      <LinePath
        data={points}
        stroke={color}
        curve={curveMonotoneX}
        strokeWidth={2}
        shapeRendering="geometricPrecision"
        {...rest}
      />
    </>
  ) : (
    <LinePath
      curve={curveMonotoneX}
      data={points}
      stroke={color}
      strokeWidth={2}
      shapeRendering="geometricPrecision"
      {...rest}
    />
  );
};

export default ChartLinePath;
