import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import Close from 'assets/close.svg?react';
import { PropsWithChildren, memo, useCallback } from 'react';
import { twJoin } from 'tailwind-merge';
import { doNothing } from 'utils/helpers';
import Divider from '../Divider';

interface DialogBaseProps extends PropsWithChildren {
  title: string;
  open: boolean;
  onCancel: () => void;
  narrower?: boolean;
  wider?: boolean;
  extraWide?: boolean;
  secure?: boolean; // disables colsing dialog by pressing esc, clicking outside of dialog, etc.
}

const DialogBase = memo(function DialogBase({
  title,
  open,
  onCancel,
  children,
  narrower = false,
  wider = false,
  extraWide = false,
  secure = false
}: DialogBaseProps) {
  const handleCancel = useCallback(
    function handleCancel() {
      onCancel();
    },
    [onCancel]
  );

  return (
    <Transition show={open}>
      <Dialog as="div" className="relative z-50" onClose={secure ? doNothing : handleCancel}>
        <TransitionChild
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500/75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 flex min-h-full w-screen flex-col items-center p-4 sm:py-10">
          <div className="h-8 w-full shrink" />
          <TransitionChild
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              className={twJoin(
                'flex h-fit max-h-[calc(100vh-32px)] w-full transform flex-col rounded-3xl bg-white text-left shadow-xl transition-all sm:max-h-[calc(100vh-80px)]',
                narrower ? 'max-w-[564px]' : wider ? 'max-w-[920px]' : extraWide ? 'max-w-[1076px]' : 'max-w-[710px]'
              )}
            >
              <header className="flex grow-0 flex-col gap-8 px-10 pt-10">
                <div className="flex items-center justify-between">
                  <DialogTitle as="h3" className="text-base font-bold">
                    {title}
                  </DialogTitle>
                  <button className="flex items-center justify-center" onClick={handleCancel}>
                    <Close width={24} height={24} className="fill-gray-700" />
                  </button>
                </div>
                <Divider />
              </header>
              {children}
            </DialogPanel>
          </TransitionChild>
          <div className="h-8 w-full shrink-[2]" />
        </div>
      </Dialog>
    </Transition>
  );
});

export default DialogBase;
