import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useFormState } from 'react-hook-form';
import { stringToValueOption } from 'utils/mappings';
import { ElementFormComponentProps, LineChartElementFormValues } from '../interfaces';

const LineChartMatchForm = ({ control, template }: ElementFormComponentProps<LineChartElementFormValues>) => {
  const formState = useFormState({ control });
  return (
    <SelectInput
      formProps={{
        control: control,
        name: 'metrics_within_match',
        rules: { required: 'Metric is required!' }
      }}
      label="Metrics"
      options={template.attribute_choices!.metrics_within_match?.map(stringToValueOption) || []}
      error={formState.errors.metrics_within_match}
    />
  );
};

export default LineChartMatchForm;
