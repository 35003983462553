import { useQueryClient } from '@tanstack/react-query';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import CircleCheck from 'assets/check-circle.svg?react';
import Download from 'assets/download.svg?react';
import Wallet from 'assets/wallet.svg?react';
import Warning from 'assets/warning.svg?react';
import { defaultPageIndex, defaultPageSize } from 'constants/tableDefaults';
import useAuth from 'contexts/auth/authContext';
import useActiveProject from 'contexts/project/projectContext';
import { PaymentSchema, ProjectGetSchema, SubscriptionGetSchema } from 'lib/model';
import { useGetProjectProjectIdSubscriptionSubscriptionIdPayments } from 'lib/payment/payment';
import { useGetSubscriptionTier } from 'lib/subscription-tier/subscription-tier';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';
import Button from 'modules/common/Button';
import GenericTable from 'modules/common/GenericTable';
import PaginationWithCount from 'modules/common/PaginationWithCount';
import TierPlanTitle from 'modules/common/TierPlanTitle';
import { memo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const columnHelper = createColumnHelper<PaymentSchema>();

function Billing() {
  const { isOwner, isAdmin } = useAuth();
  if (!isOwner && !isAdmin) {
    throw new Error('403 Forbidden');
  }
  const navigate = useNavigate();
  const { project } = useActiveProject();
  const { data: subscriptions, isPending: subscriptionsPending } = useGetSubscriptionTier(
    {},
    { query: { queryKey: ['subscriptionTiers'] } }
  );
  const { data: activeTier, isPending: activeTierPending } = useGetProjectProjectIdSubscription(
    project!.id!,
    {},
    { query: { queryKey: ['project', project.id!, 'subscription'] } }
  );
  const activeTierId = (activeTier as SubscriptionGetSchema)?.id;
  const { data: payments, isPending: paymentsPending } = useGetProjectProjectIdSubscriptionSubscriptionIdPayments(
    project!.id!,
    activeTierId!,
    { query: { queryKey: ['payments'], enabled: !!activeTier } }
  );

  function goToTierPlans() {
    navigate('/settings/tier-plans');
  }

  const [searchParams, setSearchParams] = useSearchParams();
  const pagination = {
    pageIndex: Number(searchParams.get('pageIndex') ?? defaultPageIndex),
    pageSize: Number(searchParams.get('pageSize') ?? defaultPageSize)
  };

  const table = useReactTable({
    data: payments?.objects ?? [],
    columns,
    rowCount: payments?.objects?.length,
    state: {
      pagination
    },
    onPaginationChange: (updater) => {
      let newState;
      if (updater instanceof Function) {
        newState = updater(pagination);
      } else {
        newState = updater;
      }
      searchParams.set('pageIndex', String(newState.pageIndex));
      searchParams.set('pageSize', String(newState.pageSize));
      setSearchParams(searchParams);
    },
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    renderFallbackValue: '-'
  });

  if (activeTierPending || paymentsPending || subscriptionsPending) {
    return <></>;
  }

  const currentSubscriptionTier = subscriptions!.objects!.find(
    (x) => x.id === (activeTier as SubscriptionGetSchema).subscription_tier
  )!;

  const price =
    currentSubscriptionTier.price_monthly === null ||
    currentSubscriptionTier.price_monthly === undefined ||
    currentSubscriptionTier.price_monthly === 0
      ? 'Inquire'
      : parseFloat(currentSubscriptionTier.price_monthly!.toString()).toFixed(2);

  const paymentDate =
    activeTier && (activeTier as SubscriptionGetSchema).subscription_end
      ? new Date((activeTier as SubscriptionGetSchema).subscription_end!).toLocaleDateString()
      : 'N/A';

  return (
    <section className="flex flex-col justify-around gap-6 pt-6">
      <span className="px-6 text-md font-semibold">Tier Plan</span>
      <div className="mx-6 flex flex-col gap-3 rounded-2xl bg-brand-50 p-6">
        <div className="flex justify-between">
          <TierPlanTitle name={currentSubscriptionTier.name!} />
          <span className="text-md font-semibold text-gray-900">{price}</span>
        </div>
        <div className="flex justify-between">
          <p className="text-sm text-gray-900">{currentSubscriptionTier.description}</p>
          <Button variant="secondary" size="sm" isFullWidth={false} onClick={goToTierPlans}>
            Upgrade plan
          </Button>
        </div>
      </div>
      <div className="flex justify-between px-6">
        <span className="text-sm font-medium">Payments</span>
        <span className="flex gap-2 text-sm font-medium">
          Next Invoice Issue Date: <span>{paymentDate}</span>
        </span>
      </div>
      {payments?.objects && payments?.objects.length > 0 ? (
        <>
          <GenericTable table={table} isDataFetching={false} />
          <PaginationWithCount rowCount={payments?.objects?.length} />
        </>
      ) : (
        <div className="mx-6 mb-6 flex items-center justify-center rounded-xl bg-gray-50 py-16">
          <div className="flex flex-col items-center gap-3">
            <Wallet className="size-16 fill-gray-300" />
            <span className="text-sm font-medium text-gray-500">No payments</span>
          </div>
        </div>
      )}
    </section>
  );
}

const columns = [
  columnHelper.accessor('transaction_timestamp', {
    id: 'date',
    cell: (info) => (
      <span className="mx-3 ml-6 text-xs font-medium">{(info.renderValue() as string).split('T')[0]}</span>
    ),
    header: () => (
      <span className="mx-3 ml-6 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">DATE</span>
    ),
    meta: 'w-[20%]'
  }),
  columnHelper.accessor('subscription_id', {
    id: 'tierPlan',
    cell: (info) => <span className="mx-3 text-xs font-medium">{info.getValue()}</span>,
    header: () => (
      <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">DESCRIPTION</span>
    ),
    meta: 'w-[50%]'
  }),
  columnHelper.accessor('amount', {
    id: 'amount',
    cell: (info) => <span className="mx-3 text-xs font-medium">{info.renderValue()}</span>,
    header: () => <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">TOTAL</span>,
    meta: 'w-[10%]'
  }),
  columnHelper.accessor('payment_status', {
    id: 'status',
    cell: (info) => (
      <span className="mx-3 flex items-center justify-end gap-1 text-xs font-medium">
        {info.getValue() ? (
          <>
            Paid <CircleCheck className="size-4 fill-green-600" />
          </>
        ) : (
          <>
            Payment failed <Warning className="size-4 fill-red-600" />
          </>
        )}
      </span>
    ),
    header: () => <span className="mx-3 whitespace-nowrap text-tiny font-medium uppercase text-gray-500">STATUS</span>,
    meta: 'text-end w-[15%]'
  }),
  columnHelper.display({
    id: 'action',
    cell: () => (
      <span className="flex justify-center">
        <Download className="size-4 fill-gray-900" />
      </span>
    )
  })
];

export default memo(Billing);
