import { memo } from 'react';

interface SvgTeamProps extends React.SVGProps<SVGGElement> {
  top: number;
  left: number;
  color: string;
  number: number;
}

// IMPORTANT:
// for use in <svg> only, not a standalone component
const SvgTeam = memo(function SvgTeam({ top, left, color, number, ...props }: SvgTeamProps) {
  const width = 22;
  const height = 27;

  return (
    <g
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `translate(${left - width / 2}px, ${top - height / 2}px)` }}
      {...props}
    >
      <g filter="url(#shadow)" transform="scale(1.2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={color}
          d="M17 11C17 8.23857 14.7614 6 12 6C9.23857 6 7 8.23857 7 11C7 13.7614 9.23857 16 12 16C14.7614 16 17 13.7614 17 11ZM11.1648 13H12.8348L13.5243 13.9485L13.2478 14.8015C12.8552 14.9304 12.4357 15 12 15C11.5643 15 11.1448 14.9304 10.7522 14.8015L10.4753 13.9485L11.1648 13ZM8.00111 10.9047L8.64677 10.4359L9.84327 10.825L10.3573 12.41L9.69627 13.32L8.74105 13.3198C8.27447 12.6656 8 11.8649 8 11L8.00111 10.9047ZM14.1562 10.825L15.3527 10.4359L15.9985 10.9045L16 11C16 11.8649 15.7256 12.6656 15.259 13.3198L14.3033 13.32L13.6423 12.41L14.1562 10.825ZM12.4998 7.63647L13.1457 7.1665C13.9994 7.42127 14.7335 7.95342 15.2434 8.6584L14.9663 9.50996L13.8468 9.87346L12.4998 8.89497V7.63647ZM8.7563 8.65889C9.26618 7.95368 10.0004 7.42133 10.8543 7.1665L11.4998 7.63647V8.89497L10.1528 9.87346L9.03327 9.50996L8.7563 8.65889Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill={color}
          d="M12 1L3.78307 2.82598C3.32553 2.92766 3 3.33347 3 3.80217V13.7889C3 15.795 4.00261 17.6684 5.6718 18.7812L12 23L18.3282 18.7812C19.9974 17.6684 21 15.795 21 13.7889V3.80217C21 3.33347 20.6745 2.92766 20.2169 2.82598L12 1ZM5 13.7889V4.60434L12 3.04879L19 4.60434V13.7889C19 15.1263 18.3316 16.3752 17.2188 17.1171L12 20.5963L6.7812 17.1171C5.6684 16.3752 5 15.1263 5 13.7889Z"
        />
      </g>

      {/* SHADOW */}
      <defs>
        <filter
          id="shadow"
          x="-2"
          y="-4"
          width="56"
          height="56"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="6" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2182_58324" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2182_58324" result="shape" />
        </filter>
      </defs>
    </g>
  );
});

export default SvgTeam;
