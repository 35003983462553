import { MatchDetailed, OptionTypes } from './interfaces';

export function filterMatchBySeason(match: MatchDetailed, seasons: OptionTypes) {
  switch (true) {
    case !seasons || (Array.isArray(seasons) && seasons.length === 0):
      return true;
    case Array.isArray(seasons):
      return seasons.some((s) => s.id === match.season);
    default:
      return match.season === seasons?.id;
  }
}

export function filterMatchByCompetition(match: MatchDetailed, competitions: OptionTypes) {
  switch (true) {
    case !competitions || (Array.isArray(competitions) && competitions.length === 0):
      return true;
    case Array.isArray(competitions):
      return competitions.some((c) => c.id === match.competition_id);
    default:
      return match.competition_id === competitions?.id;
  }
}
