import { memo } from 'react';
// import ComparisonElementForm from './ComparisonElementForm';
import DynamicElementForm from './DynamicElementForm';
import EventMapForm from './EventMapForm';
import HeatMapElementForm from './HeatMapElementForm/HeatMapElementForm';
import PositionMapElementForm from './PositionMapElementForm';
import RadarChartElementForm from './RadarChart/RadarChartElementForm';
import ScatterChartElementForm from './ScatterChart/ScatterChartElementForm';
import TableElementForm from './Table/TableElementForm';
import ComparisonElementForm from './ComparisonChart/ComparisonElementForm';
import VersusElementForm from './VersusElementForm';
import { ElementFormProps } from './interfaces';
import LineChartElementForm from './LineChart/LineChartElementForm';

const ElementForm = memo(function ElementForm(props: ElementFormProps) {
  if (!props.template?.attribute_choices) {
    return null;
  }

  switch (props.template.name) {
    case 'heatmap':
    case 'heatmap-tracking-data': // does BE return this or heatmap?
    case 'heatmap-event-data':
      return <HeatMapElementForm {...props} />;
    case 'avg-positions':
    case 'avg-positions-tracking-data': // does BE return this or avg-positions?
    case 'avg-positions-event-data':
      return <PositionMapElementForm {...props} />;
    case 'radar-chart':
      return <RadarChartElementForm {...props} />;
    case 'table':
      return <TableElementForm {...props} />;
    case 'line-chart':
      return <LineChartElementForm {...props} />;
    case 'comparison':
      return <ComparisonElementForm {...props} />;
    case 'scatter-chart':
      return <ScatterChartElementForm {...props} />;
    case 'versus':
      return <VersusElementForm {...props} />;
    case 'event-map':
    case 'event-animation-tracking-data':
      return <EventMapForm {...props} />;
    default:
      return <DynamicElementForm {...props} />;
  }
});

export default ElementForm;
