import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import { stringToValueOption } from 'utils/mappings';
import { attributeValuesMatchTime, attributeValuesOption, loadAttributeValues } from './helpers';
import { ElementFormProps, ElementFormValues } from './interfaces';
import FormMatchSelection from './components/FormMatchSelection';

interface PositionMapElementFormValues extends ElementFormValues {
  position_type: ValueOption | null;
  matches: ValueOption | null;
  seasons: ValueOption | null;
  competitions: ValueOption | null;
  match_time: ValueOption | null;
}

const PositionMapElementForm = memo(function PositionMapElementForm({
  template,
  onSubmitForm,
  element,
  report
}: ElementFormProps) {
  const defaultValues: Partial<PositionMapElementFormValues> = {
    position_type: null,
    matches: null,
    seasons: null,
    competitions: null,
    match_time: attributeValuesMatchTime([2])
  };
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
  } else {
    if (template.attribute_choices?.position_type.length > 0) {
      defaultValues.position_type = attributeValuesOption(template.attribute_choices?.position_type);
    }
  }
  const form = useForm<PositionMapElementFormValues>({ defaultValues });
  const { control, formState, handleSubmit } = form;

  const addElement = useCallback(
    function addElement(data: PositionMapElementFormValues) {
      onSubmitForm(data);
    },
    [onSubmitForm]
  );

  const positionTypeOptions = useMemo(() => {
    return template.attribute_choices?.position_type?.map(stringToValueOption) || [];
  }, [template.attribute_choices?.position_type]);

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      <SelectInput
        formProps={{
          name: 'position_type',
          control: control,
          rules: { required: 'Position type is required!' }
        }}
        disabled={!!defaultValues.position_type && positionTypeOptions.length === 1}
        label={'Position Type'}
        options={positionTypeOptions}
        error={formState.errors.position_type}
      />
      {report.report_type !== 'match' && (
        <FormMatchSelection
          form={form}
          seasonsFormName="seasons"
          competitionsFormName="competitions"
          matchesFormName="matches"
          allSeasons={template.attribute_choices!.seasons ?? []}
          allCompetitions={template.attribute_choices!.competitions ?? []}
          allMatches={template.attribute_choices!.matches ?? []}
        />
      )}
    </form>
  );
});

export default PositionMapElementForm;
