import { ReportSchema } from 'lib/model';
import { Fragment, memo } from 'react';
import {
  LeagueGeneralData,
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  ScoutGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../../reportPage/components/reportCards/interfaces';

interface ReportDescriptionProps {
  report: ReportSchema;
}

function ReportDescription({ report }: ReportDescriptionProps) {
  switch (report.report_type) {
    case 'match': {
      const data = report.general_data as MatchGeneralData;
      return (
        <span className="flex items-center gap-1">
          <span className="text-tiny font-medium text-gray-500">
            {`${data.home_team_name} - ${data.away_team_name} (${data.home_team_score}:${data.away_team_score})`}
          </span>
          <span className="h-3 w-px bg-gray-300" />
          <span className="text-tiny font-medium text-gray-500">{data.competition_name}</span>
          <span className="h-3 w-px bg-gray-300" />
          <span className="text-tiny font-medium text-gray-500">{new Date(data.date).toLocaleDateString()}</span>
        </span>
      );
    }
    case 'player': {
      const data = report.general_data as PlayerGeneralData;
      return <span className="text-tiny font-medium text-gray-500">{data.player_name}</span>;
    }
    case 'player_comparison': {
      const data = report.general_data as PlayerComparisonGeneralData;
      return (
        <span className="flex items-center gap-1">
          <span className="text-tiny font-medium text-gray-500">{data.player1.player_name}</span>
          <span className="h-3 w-px bg-gray-300" />
          <span className="text-tiny font-medium text-gray-500">{data.player2.player_name}</span>
        </span>
      );
    }
    case 'team': {
      const data = report.general_data as TeamGeneralData;
      return <span className="text-tiny font-medium text-gray-500">{data.team_name}</span>;
    }
    case 'team_comparison': {
      const data = report.general_data as TeamComparisonGeneralData;
      return (
        <span className="flex items-center gap-1">
          <span className="text-tiny font-medium text-gray-500">{data.team1.team_name}</span>
          <span className="h-3 w-px bg-gray-300" />
          <span className="text-tiny font-medium text-gray-500">{data.team2.team_name}</span>
        </span>
      );
    }
    case 'scout': {
      const data = report.general_data as ScoutGeneralData;
      const allPlayers = [...data.players_filtered, ...data.players_manual];
      return (
        <span className="flex items-center gap-1">
          {allPlayers.slice(0, 3).map((player, i) => (
            <Fragment key={player.player_id}>
              {i > 0 && <span className="h-3 w-px bg-gray-300" />}
              <span className="text-tiny font-medium text-gray-500">{player.player_name}</span>
            </Fragment>
          ))}
          {allPlayers.length > 3 && (
            <>
              <span className="h-3 w-px bg-gray-300" />
              <span className="text-tiny font-medium text-gray-500">+{allPlayers.length - 3}</span>
            </>
          )}
        </span>
      );
    }
    case 'league': {
      const data = report.general_data as LeagueGeneralData;
      return <span className="text-tiny font-medium text-gray-500">{data.competition_name}</span>;
    }
    default:
      return null;
  }
}

export default memo(ReportDescription);
