import { useQueryClient } from '@tanstack/react-query';
import { DefaultConfigurationFormValues } from 'constants/defaultValues';
import { dataSourceRefreshFrequencyOptions } from 'constants/formOptions';
import { CRONTimeOptions, seasonOptions } from 'constants/generated';
import useActiveProject from 'contexts/project/projectContext';
import {
  SelectedDataSource,
  SelectedDataSourceState,
  useSelectedDataSources
} from 'contexts/selectedDataSourcesContext';
import { DatasourceConfigurationsNestedSchema, DatasourceSchema } from 'lib/model';
import Button from 'modules/common/Button';
import DataSourceConfigurationForm from 'modules/dataSources/components/DataSourceConfigurationForm';
import { memo, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ConfigurationFormValues, ValueOption } from 'utils/interfaces';

interface ConfigurationFormProps {
  dataSource: DatasourceSchema;
  state: SelectedDataSourceState;
}

const ConfigurationForm = memo(function ConfigurationForm({ dataSource, state }: ConfigurationFormProps) {
  const { selectedDataSources, setSelectedDataSources } = useSelectedDataSources();

  const queryClient = useQueryClient();
  const { project } = useActiveProject();
  const dataSourceConfigs = queryClient.getQueryData<DatasourceConfigurationsNestedSchema>([
    'project-configuration',
    project.project_configuration_latest,
    'datasource-configuration'
  ]);

  const { refreshFrequency, time } = useMemo(() => {
    let refreshFrequency: ValueOption = DefaultConfigurationFormValues.refreshFrequency;
    let time: ValueOption = DefaultConfigurationFormValues.time;

    // Check if any data source is already configured and copy their frequency
    if (selectedDataSources.length > 1 && selectedDataSources.some((option) => !!option.state.configuration)) {
      const selectedDataSourcesWithConfig = selectedDataSources.find((option) => option.state.configuration)!;
      refreshFrequency = selectedDataSourcesWithConfig.state.configuration!.refreshFrequency;
      time = selectedDataSourcesWithConfig.state.configuration!.time;
    } else if (dataSourceConfigs && dataSourceConfigs.objects && dataSourceConfigs.objects.length > 0) {
      const frequency = dataSourceConfigs.objects[0].frequency!;
      refreshFrequency =
        dataSourceRefreshFrequencyOptions.find((x) => x.id === frequency.substring(4).trimStart()) ?? refreshFrequency;
      time = CRONTimeOptions.find((x) => x.id === frequency.substring(0, 4).trimStart()) ?? time;
    }

    return { refreshFrequency: refreshFrequency, time: time };
  }, [selectedDataSources[0].state.configuration?.refreshFrequency, selectedDataSources[0].state.configuration?.time]);

  const { control, handleSubmit, setValue } = useForm<ConfigurationFormValues>({
    defaultValues: {
      areas: state.configuration?.areas ?? DefaultConfigurationFormValues.areas,
      leagues: state.configuration?.leagues ?? DefaultConfigurationFormValues.leagues,
      min_season: state.configuration?.min_season ?? DefaultConfigurationFormValues.min_season,
      // Assuming all data sources have identical refresh frequency
      refreshFrequency: state.configuration?.refreshFrequency ?? refreshFrequency,
      time: state.configuration?.time ?? time
    },
    mode: 'all'
  });

  useEffect(() => {
    if (!state.configuration?.refreshFrequency) {
      setValue('refreshFrequency', refreshFrequency);
    }
    if (!state.configuration?.time) {
      setValue('time', time);
    }
  }, [refreshFrequency, time]);

  function onSubmit(formData: ConfigurationFormValues) {
    const newRefreshFrequency = formData.refreshFrequency;
    const newTime = formData.time;
    setSelectedDataSources((prev) => {
      const next = prev.map((x) => {
        if (x.dataSource.id === dataSource.id) {
          return {
            ...x,
            state: {
              ...x.state,
              configured: true,
              configuration: {
                areas: formData.areas,
                leagues: formData.leagues,
                min_season: formData.min_season,
                refreshFrequency: formData.refreshFrequency,
                time: formData.time
              }
            }
          };
        }
        return {
          ...x,
          state: {
            ...x.state,
            configuration: {
              ...x.state.configuration,
              refreshFrequency: newRefreshFrequency,
              time: newTime
            }
          }
        } as SelectedDataSource;
      });
      const configuredIndex = next.findIndex((x) => x.dataSource.id === dataSource.id);
      if (configuredIndex < next.length - 1) {
        next[configuredIndex + 1] = {
          ...next[configuredIndex + 1],
          state: { ...next[configuredIndex + 1].state, expanded: true }
        };
      }
      return next;
    });
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="flex flex-col gap-2">
        <span className="text-base font-semibold">Configure Data Source</span>
        <p className="flex flex-col text-sm">
          <span>Customize your data source to get more specific data.</span>
          <span>You can always change it later.</span>
        </p>
      </div>
      <DataSourceConfigurationForm dataSource={dataSource} control={control} handleSubmit={handleSubmit(onSubmit)} />
      <div className="mt-4">
        <Button isSubmitButton={true} size="lg" form="data-source-configuration-form">
          Save configuration preferences
        </Button>
      </div>
    </div>
  );
});

export default ConfigurationForm;
