import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { ValueOption } from 'utils/interfaces';
import {
  competitionToValueOption,
  matchToValueOption,
  metricToMetricOption,
  seasonToValueOption,
  stringToValueOption
} from 'utils/mappings';
import { getMetricOptionsFromSeasons } from '../../helpers';
import { loadAttributeValues } from '../helpers';
import { ElementFormProps, LineChartElementFormValues } from '../interfaces';
import LineChartLeagueForm from './LineChartLeagueForm';
import LineChartMatchForm from './LineChartMatchForm';

const LineChartElementForm = memo(function LineChartElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<LineChartElementFormValues> = {
    type: null,
    time_scale: null,
    metrics_within_match: null,
    metrics_within_season_match: null,
    metrics_within_season_season: null,
    match_id: null,
    match_id_team1: null,
    match_id_team2: null,
    match_id_player1: null,
    match_id_player2: null,
    seasons: [],
    matches: [],
    competitions: []
  };
  if (element?.attribute_values) {
    loadAttributeValues(defaultValues, element, template);
    if (report.report_type === 'match') {
      defaultValues.type = {
        label: 'For specific match',
        id: 'For specific match'
      };
    }
  } else {
    switch (report.report_type) {
      case 'player':
      case 'player_comparison':
      case 'scout':
        defaultValues.type = {
          label: 'For player',
          id: 'For player'
        };
        break;
      case 'team':
      case 'team_comparison':
      case 'league':
        defaultValues.type = {
          label: 'For team',
          id: 'For team'
        };
        break;
      case 'match':
        defaultValues.type = {
          label: 'For specific match',
          id: 'For specific match'
        };
    }
  }

  const { control, formState, handleSubmit, watch, getValues, setValue, resetField } =
    useForm<LineChartElementFormValues>({
      defaultValues
    });

  const type = watch('type');
  const timeScale = watch('time_scale');
  const seasons = watch('seasons');

  const addElement = useCallback(
    function addElement(data: LineChartElementFormValues) {
      const formSeasons = getValues('seasons');
      const formData = { ...data };

      if (timeScale?.id === 'By season' && formSeasons.length === 0) {
        formData.seasons = template.attribute_choices!.seasons.map(seasonToValueOption);
      }

      onSubmitForm(formData);
    },
    [getValues, onSubmitForm, template.attribute_choices, timeScale?.id]
  );

  const metricsMatchOptions = useMemo(() => {
    const baseMetrics = template.attribute_choices!.metrics_within_match;
    return getMetricOptionsFromSeasons(seasons, baseMetrics);
  }, [template.attribute_choices, seasons]);

  const metricsSeasonMatchOptions = useMemo(() => {
    return template.attribute_choices!.metrics_within_season_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const metricsSeasonSeasonOptions = useMemo(() => {
    const baseMetrics = template.attribute_choices!.metrics_within_season_season;
    return getMetricOptionsFromSeasons(seasons, baseMetrics);
  }, [template.attribute_choices, seasons]);

  const xAxisOptions = useMemo(() => {
    if (report.report_type === 'match') {
      return [];
    }

    let activeOptions: string[] = [];
    if (type?.id === 'For specific match') {
      activeOptions = template.attribute_choices!.time_scale.filter((scale: string) => scale === 'Match time');
    } else {
      activeOptions = template.attribute_choices!.time_scale.filter((scale: string) => scale !== 'Match time');
    }
    return activeOptions?.map(stringToValueOption) || [];
  }, [template.attribute_choices, report.report_type, type]);

  useEffect(() => {
    // Check if match time exists as an option
    const activeTimeScale = getValues('time_scale') as ValueOption | null; // x-axis
    if (
      type?.id === 'For specific match' &&
      template.attribute_choices!.time_scale?.find((scale: string) => scale === 'Match time')
    ) {
      setValue('time_scale', stringToValueOption('Match time'));
    } else if (type?.id !== 'For specific match' && activeTimeScale?.id === 'Match time') {
      setValue('time_scale', null);
    }

    // Reset values
    if (type?.id !== 'For specific match') {
      resetField('match_id');
      resetField('match_id_team1');
      resetField('match_id_team2');
      resetField('match_id_player1');
      resetField('match_id_player2');
      setValue('metrics_within_match', null);
    } else {
      setValue('metrics_within_season_match', null);
      setValue('metrics_within_season_season', null);
      resetField('seasons');
    }
  }, [resetField, setValue, getValues, type, template.attribute_choices]);

  const renderMatchSelection = useCallback(() => {
    let matchSelection: JSX.Element | null = null;
    if (
      type?.id === 'For specific match' &&
      ['player', 'team', 'player_comparison', 'team_comparison'].includes(report.report_type!)
    ) {
      if (report.report_type === 'player' || report.report_type === 'team') {
        matchSelection = (
          <SelectInput
            formProps={{
              control: control,
              name: 'match_id',
              rules: { required: 'Match is required!' }
            }}
            label={'Choose Match'}
            searchable
            options={template.attribute_choices!.match_id?.map(matchToValueOption) || []}
            error={formState.errors.match_id}
          />
        );
      } else if (report.report_type === 'player_comparison' || report.report_type === 'team_comparison') {
        let keyElement1 = '';
        let keyElement2 = '';
        let labelElement1 = '';
        let labelElement2 = '';
        if (report.report_type === 'player_comparison') {
          keyElement1 = 'match_id_player1';
          labelElement1 = 'Choose Match for Player 1';
          keyElement2 = 'match_id_player2';
          labelElement2 = 'Choose Match for Player 2';
        } else {
          keyElement1 = 'match_id_team1';
          labelElement1 = 'Choose Match for Team 1';
          keyElement2 = 'match_id_team2';
          labelElement2 = 'Choose Match for Team 2';
        }

        matchSelection = (
          <>
            <SelectInput
              formProps={{
                control: control,
                name: keyElement1,
                rules: { required: 'Match is required!' }
              }}
              label={labelElement1}
              searchable
              options={template.attribute_choices![keyElement1]?.map(matchToValueOption) || []}
              error={formState.errors[keyElement1]}
            />
            <SelectInput
              formProps={{
                control: control,
                name: keyElement2,
                rules: { required: 'Match is required!' }
              }}
              label={labelElement2}
              searchable
              options={template.attribute_choices![keyElement2]?.map(matchToValueOption) || []}
              error={formState.errors[keyElement2]}
            />
          </>
        );
      }
    }
    return matchSelection;
  }, [report.report_type, control, formState.errors, template.attribute_choices, type]);

  const currentMetrics = useMemo(() => {
    let activeMetric = '';
    let options: ValueOption[] = [];
    switch (timeScale?.id) {
      case 'Match time':
        activeMetric = 'metrics_within_match';
        options = metricsMatchOptions;
        break;
      case 'By match':
      case 'By match (last 10)':
        activeMetric = 'metrics_within_season_match';
        options = metricsSeasonMatchOptions;
        break;
      case 'By season':
        activeMetric = 'metrics_within_season_season';
        options = metricsSeasonSeasonOptions;
        break;
    }
    return { currentMetric: activeMetric, currentOptions: options };
  }, [metricsSeasonMatchOptions, metricsSeasonSeasonOptions, metricsMatchOptions, timeScale]);

  useEffect(() => {
    switch (currentMetrics.currentMetric) {
      case 'metrics_within_match':
        setValue('metrics_within_season_match', null);
        setValue('metrics_within_season_season', null);
        break;
      case 'metrics_within_season_match':
        setValue('metrics_within_match', null);
        setValue('metrics_within_season_season', null);
        break;
      case 'metrics_within_season_season':
        setValue('metrics_within_match', null);
        setValue('metrics_within_season_match', null);
        break;
    }
    const activeMetric = getValues(currentMetrics.currentMetric) as ValueOption | null;
    if (!currentMetrics.currentOptions.find((option) => option.id === activeMetric?.id)) {
      setValue(currentMetrics.currentMetric, null);
    }
  }, [currentMetrics, getValues, setValue]);

  const renderForm = () => {
    switch (report.report_type) {
      case 'match':
        return <LineChartMatchForm control={control} template={template} />;
      case 'league':
        return <LineChartLeagueForm control={control} template={template} />;
      default:
        return (
          <>
            <SelectInput
              formProps={{
                control: control,
                name: 'type',
                rules: { required: 'Type is required!' }
              }}
              label={'Type'}
              disabled={report.report_type === 'scout'}
              options={template.attribute_choices!.type?.map(stringToValueOption) || []}
              error={formState.errors.type}
            />
            {type?.id === 'For specific match' && renderMatchSelection()}
            <div className="flex gap-6">
              <SelectInput
                formProps={{
                  control: control,
                  name: 'time_scale',
                  rules: { required: 'Time scale is required!' }
                }}
                label={'X - Axis'}
                placeholder="Time scale"
                options={xAxisOptions}
                error={formState.errors.time_scale}
              />
              {timeScale === null ? (
                <SelectInput
                  formProps={{
                    control: control,
                    name: 'placeholder',
                    rules: { required: 'Metric is required!' }
                  }}
                  disabled={true}
                  label={'Main attribute'}
                  placeholder="Metric"
                  options={[]}
                  error={formState.errors[currentMetrics.currentMetric]}
                />
              ) : (
                <SelectInput
                  formProps={{
                    control: control,
                    name: currentMetrics.currentMetric,
                    rules: { required: 'Metric is required!' }
                  }}
                  label={'Y - axis'}
                  placeholder="Metric"
                  options={currentMetrics.currentOptions}
                  error={formState.errors[currentMetrics.currentMetric]}
                />
              )}
            </div>
            {timeScale && timeScale?.id !== 'Match time' && template.attribute_choices?.seasons && (
              <SelectInput
                formProps={{
                  control: control,
                  name: 'seasons'
                }}
                multiple
                label={'Seasons'}
                placeholder="All"
                options={template.attribute_choices.seasons.map(seasonToValueOption)}
              />
            )}
            {template.attribute_choices?.competitions && (
              <div>
                <SelectInput
                  formProps={{
                    control: control,
                    name: 'competitions'
                  }}
                  multiple
                  label={'Competitions'}
                  placeholder="All"
                  options={template.attribute_choices?.competitions.map(competitionToValueOption) ?? []}
                />
                <div className="mt-1 text-sm text-gray-600">
                  Competition metric averages will be shown for better comparison.
                </div>
              </div>
            )}
          </>
        );
    }
  };

  return (
    <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
      {renderForm()}
    </form>
  );
});

export default LineChartElementForm;
