import SelectInput from 'modules/common/Form/Select/SelectInput';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Metric, MetricOption, ValueOption } from 'utils/interfaces';
import { metricToMetricOption, playerToValueOption, stringToValueOption } from 'utils/mappings';
import { showAggregateOptions } from '../../helpers';
import AggregateOptions from '../AggregateOptions';
import { loadAttributeValues } from '../helpers';
import { ElementFormProps, RadarChartElementFormValues } from '../interfaces';
import RadarChartLeagueForm from './RadarChartLeagueForm';

const RadarChartElementForm = memo(function RadarChartElementForm({
  template,
  report,
  onSubmitForm,
  element
}: ElementFormProps) {
  const defaultValues: Partial<RadarChartElementFormValues> = {
    attribute_type: null,
    aggregate_by: {
      label: 'Match',
      id: 'match'
    },
    aggregation_metric: undefined,
    players: [],
    metrics_teams_match: [],
    metrics_players_match: [],
    metrics_players_season: [],
    metrics_teams_season: [],
    seasons: [],
    matches: [],
    competitions: []
  };
  if (element?.attribute_values) {
    // Editing element
    loadAttributeValues(defaultValues, element, template);
  } else {
    // Creating element
    switch (report.report_type) {
      case 'team_comparison':
      case 'league':
        defaultValues.attribute_type = {
          label: 'Team',
          id: 'team'
        };
        break;
      default:
        defaultValues.attribute_type = {
          label: 'Player',
          id: 'player'
        };
        break;
    }
  }

  const radarForm = useForm<RadarChartElementFormValues>({
    defaultValues
  });
  const { control, watch, handleSubmit, setValue, getValues, formState } = radarForm;
  const attributeType = watch('attribute_type');
  const playerMetrics = watch('metrics_players_match');
  const teamMetrics = watch('metrics_teams_match');

  const addElement = useCallback(
    function addElement(data: RadarChartElementFormValues) {
      if (template!.attribute_choices!.players) {
        const players = getValues('players');
        if (attributeType?.id === 'player' && players.length === 0) {
          data.players = template.attribute_choices!.players.map(playerToValueOption);
        }
      }

      onSubmitForm(data);
    },
    [attributeType?.id, getValues, onSubmitForm, template]
  );

  const attributeTypeOptions = useMemo(
    () => template.attribute_choices!.attribute_type?.map(stringToValueOption) || [],
    [template.attribute_choices]
  );

  const playerOptions = useMemo(
    () => template.attribute_choices!.players?.map(playerToValueOption) || [],
    [template.attribute_choices]
  );

  // Resetting values on change
  useEffect(() => {
    if (attributeType?.id === 'team') {
      setValue('players', []);
      setValue('metrics_players_match', []);
    } else {
      setValue('metrics_teams_match', []);
    }
  }, [attributeType, setValue]);

  const active_metrics: MetricOption[] = useMemo(() => {
    if (attributeType?.id === 'team') {
      return teamMetrics;
    } else {
      return playerMetrics;
    }
  }, [attributeType, playerMetrics, teamMetrics]);

  const metricsTeamsMatchOptions = useMemo(() => {
    const metrics = (template.attribute_choices!.metrics_teams_match as Metric[]) || [];
    const filteredMetrics = metrics.filter((metric) => metric.min_value >= 0);
    return filteredMetrics.map(metricToMetricOption);
  }, [template.attribute_choices]);

  const metricsPlayersMatchOptions = useMemo(() => {
    const metrics = (template.attribute_choices!.metrics_players_match as Metric[]) || [];
    const filteredMetrics = metrics.filter((metric) => metric.min_value >= 0);
    return filteredMetrics.map(metricToMetricOption);
  }, [template.attribute_choices]);

  const renderMetrics = useCallback(() => {
    let active_metric = '';
    let metric_options = [];
    switch (attributeType?.id) {
      case 'team':
        active_metric = 'metrics_teams_match';
        metric_options = metricsTeamsMatchOptions;
        break;
      case 'player':
        active_metric = 'metrics_players_match';
        metric_options = metricsPlayersMatchOptions;
        break;
      default:
        return (
          <SelectInput
            formProps={{
              control: control,
              name: 'placeholder'
            }}
            multiple
            key={'placeholder'}
            label={'Select aggregation data'}
            disabled={true}
            options={[]}
          />
        );
    }

    return (
      <SelectInput
        formProps={{
          control: control,
          name: active_metric,
          rules: {
            validate: {
              minLength: (values: ValueOption[]) => values?.length > 2 || 'At least three metrics are required!'
            }
          }
        }}
        multiple
        key={active_metric}
        label={'Metrics'}
        placeholder={'Choose metrics to aggregate'}
        disabled={attributeType === null}
        options={metric_options}
        error={formState.errors[active_metric]}
      />
    );
  }, [attributeType, control, formState.errors, metricsPlayersMatchOptions, metricsTeamsMatchOptions]);

  const renderForm = () => {
    switch (report.report_type) {
      case 'league':
        return <RadarChartLeagueForm control={control} template={template} />;
      default:
        return (
          <>
            {template.attribute_choices!.attribute_type && (
              <SelectInput
                formProps={{
                  control: control,
                  name: 'attribute_type',
                  rules: {
                    required: 'Attribute type is required!'
                  }
                }}
                label={'Attribute type'}
                options={attributeTypeOptions}
                disabled={attributeTypeOptions.length === 1}
                error={formState.errors.attribute_type}
              />
            )}
            {renderMetrics()}
            {showAggregateOptions(report) && <AggregateOptions template={template} metrics={active_metrics} />}
            {attributeType?.id === 'player' && template.attribute_choices!.players && (
              <SelectInput
                formProps={{
                  control: control,
                  name: 'players'
                }}
                multiple
                label={'Players'}
                placeholder="All"
                options={playerOptions}
              />
            )}
          </>
        );
    }
  };

  return (
    <FormProvider {...radarForm}>
      <form className="flex w-full flex-col gap-6" id="new-element-form" onSubmit={handleSubmit(addElement)}>
        {renderForm()}
      </form>
    </FormProvider>
  );
});

export default RadarChartElementForm;
