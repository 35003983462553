import { ReportElementTemplateSchema } from 'lib/model';
import Checkbox from 'modules/common/Form/Checkbox';
import { memo, useEffect, useMemo } from 'react';
import { UseFormReturn, useFormState, useWatch } from 'react-hook-form';
import { MatchDetailed, OptionTypes } from 'utils/interfaces';

import SelectInput from 'modules/common/Form/Select/SelectInput';
import { filterMatchByCompetition, filterMatchBySeason } from 'utils/filters';
import {
  competitionToValueOption,
  matchDetailedToValueOption,
  playerToValueOption,
  seasonToValueOption,
  stringToValueOption
} from 'utils/mappings';
import { SubjectHeatMapElementFormValues } from '../../interfaces';
import FormMatchSelection from '../../components/FormMatchSelection';

interface SubjectHeatMapElementFormProps {
  template: ReportElementTemplateSchema;
  heatMapElementForm: UseFormReturn<SubjectHeatMapElementFormValues>;
  subject1: string;
  subject2?: string;
}

const MatchLabels = ({ options }: { options: OptionTypes }) => {
  const showMultiple = Array.isArray(options);
  if (showMultiple) {
    return (
      <div className="flex flex-col gap-1">
        {options.map((option) => (
          <span className="text-xs font-semibold" key={option.id}>
            {option.label}
          </span>
        ))}
      </div>
    );
  } else if (options) {
    return <span className="text-xs font-semibold">{options.label}</span>;
  } else {
    return <></>;
  }
};

const SubjectHeatMapElementForm = memo(function SubjectHeatMapElementForm({
  template,
  heatMapElementForm,
  subject1,
  subject2
}: SubjectHeatMapElementFormProps) {
  const formState = useFormState(heatMapElementForm);
  const showMultiple = template.name === 'heatmap-event-data';
  const hasComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'has_comparison'
  }) as boolean;

  const seasons = useWatch({
    control: heatMapElementForm.control,
    name: 'seasons'
  }) as OptionTypes;
  const competitions = useWatch({
    control: heatMapElementForm.control,
    name: 'competitions'
  }) as OptionTypes;
  const matches = useWatch({
    control: heatMapElementForm.control,
    name: 'matches'
  }) as OptionTypes;

  function filterMatchesOptions(matches: MatchDetailed[], seasons: OptionTypes, competitions: OptionTypes) {
    return (
      matches
        .filter((option: MatchDetailed) => filterMatchBySeason(option, seasons))
        .filter((option: MatchDetailed) => filterMatchByCompetition(option, competitions))
        ?.map(matchDetailedToValueOption) || []
    );
  }
  const matchOptions = useMemo(() => {
    return filterMatchesOptions(template.attribute_choices!.matches, seasons, competitions);
  }, [competitions, seasons, template.attribute_choices]);

  // Update active matches based on available options
  useEffect(() => {
    const activeMatches = heatMapElementForm.getValues('matches') as OptionTypes;
    if (activeMatches) {
      if (Array.isArray(activeMatches)) {
        const newMatches = activeMatches.filter((match) => matchOptions.some((option) => option.id === match.id));
        heatMapElementForm.setValue('matches', newMatches);
      } else {
        const newMatches = matchOptions.find((option) => option.id === activeMatches.id);
        heatMapElementForm.setValue('matches', newMatches ?? null);
      }
    }
  }, [matchOptions, heatMapElementForm]);

  const matchComparison = useWatch({
    control: heatMapElementForm.control,
    name: 'matches_comparison'
  }) as OptionTypes;

  useEffect(() => {
    if (!hasComparison && subject2 === undefined) {
      const comparisonData: OptionTypes = showMultiple ? [] : null;
      heatMapElementForm.setValue('seasons_comparison', comparisonData);
      heatMapElementForm.setValue('competitions_comparison', comparisonData);
      heatMapElementForm.setValue('matches_comparison', comparisonData);
      heatMapElementForm.setValue('players_comparison', []);
      heatMapElementForm.setValue('event_types_comparison', []);
    }
  }, [hasComparison, heatMapElementForm]);

  return (
    <>
      <div className="flex flex-col gap-6">
        {subject2 && <div className="text-md font-semibold">{subject1}</div>}
        <FormMatchSelection
          form={heatMapElementForm}
          multiple={showMultiple}
          seasonsFormName="seasons"
          competitionsFormName="competitions"
          matchesFormName="matches"
          allSeasons={template.attribute_choices!.seasons ?? []}
          allCompetitions={template.attribute_choices!.competitions ?? []}
          allMatches={template.attribute_choices!.matches ?? []}
        />
        {template.attribute_choices!.players && (
          <SelectInput
            formProps={{
              control: heatMapElementForm.control,
              name: 'players'
            }}
            multiple
            label="Players"
            placeholder="All"
            options={template.attribute_choices!.players?.map(playerToValueOption) || []}
            error={formState.errors.players}
          />
        )}
        {template.attribute_choices?.event_types && (
          <SelectInput
            formProps={{
              control: heatMapElementForm.control,
              name: 'event_types'
            }}
            multiple
            label={'Event Types'}
            placeholder="All"
            options={template.attribute_choices!.event_types?.map(stringToValueOption) || []}
            error={formState.errors.event_types}
          />
        )}
        {subject2 === undefined && (
          <Checkbox
            label="Include a comparison map"
            registerReturn={{ ...heatMapElementForm.register('has_comparison') }}
          />
        )}
        {(hasComparison || subject2) && (
          <>
            <div className="text-md font-semibold">{subject2 ? subject2 : 'Comparison Options'}</div>
            <FormMatchSelection
              form={heatMapElementForm}
              multiple={showMultiple}
              seasonsFormName="seasons_comparison"
              competitionsFormName="competitions_comparison"
              matchesFormName="matches_comparison"
              allSeasons={template.attribute_choices!.seasons_comparison ?? []}
              allCompetitions={template.attribute_choices!.competitions_comparison ?? []}
              allMatches={template.attribute_choices!.matches_comparison ?? []}
            />

            {template.attribute_choices!.players_comparison && (
              <SelectInput
                formProps={{
                  control: heatMapElementForm.control,
                  name: 'players_comparison'
                }}
                multiple
                label="Comparison players"
                placeholder="All"
                options={template.attribute_choices!.players_comparison?.map(playerToValueOption) || []}
                error={formState.errors.players_comparison}
              />
            )}
            {template.attribute_choices?.event_types_comparison && (
              <SelectInput
                formProps={{
                  control: heatMapElementForm.control,
                  name: 'event_types_comparison'
                }}
                multiple
                label={'Event Types'}
                placeholder="All"
                options={template.attribute_choices!.event_types_comparison?.map(stringToValueOption) || []}
                error={formState.errors.event_types_comparison}
              />
            )}
          </>
        )}
      </div>

      <div className="flex flex-col gap-6">
        {subject2 || hasComparison ? (
          <div className="grid grid-cols-2 gap-x-6 gap-y-3">
            <div className="text-md font-semibold">{subject1} </div>
            <div className="text-md font-semibold">{subject2 ? subject2 : 'Comparision'}</div>
            <div>{matches && <MatchLabels options={matches} />}</div>
            <div>{matchComparison && <MatchLabels options={matchComparison} />}</div>
          </div>
        ) : (
          <>
            <div className="text-md font-semibold">{subject1}</div>
            <div>{matches && <MatchLabels options={matches} />}</div>
          </>
        )}
      </div>
    </>
  );
});

export default SubjectHeatMapElementForm;
