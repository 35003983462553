import { memo } from 'react';
import ElementRow from '../../elements/ElementRow';

import { LeagueGeneralData } from '../interfaces';
import LeagueHeader from './LeagueHeader';

interface LeagueElementProps {
  league: LeagueGeneralData;
}

const LeagueElement = memo(function LeagueElement({ league }: LeagueElementProps) {
  return (
    <div className="flex flex-col gap-6">
      <LeagueHeader league={league} textSize="lg" iconSize="md" />
      <div className="flex flex-col gap-2">
        <ElementRow>
          <span className="text-tiny uppercase">COUNTRY</span>
          <span>{league.competition_country ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">AREA</span>
          <span>{league.competition_area ?? '-'}</span>
        </ElementRow>
        <ElementRow>
          <span className="text-tiny uppercase">GENDER</span>
          <span>{league.competition_gender?.toUpperCase() ?? '-'}</span>
        </ElementRow>
      </div>
    </div>
  );
});

export default LeagueElement;
