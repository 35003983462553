import { memo } from 'react';
import { toShortNumber } from 'utils/helpers';
import ElementComparisonRow from '../ElementComparisonRow';
import TeamComparisonHeader from './TeamComparisonHeader';

import { TeamComparisonGeneralData } from '../interfaces';

const TeamComparisonElement = memo(function TeamComparisonElement({ team1, team2 }: TeamComparisonGeneralData) {
  return (
    <div className="flex flex-col gap-6">
      <TeamComparisonHeader team1={team1} team2={team2} textSize="sm" iconSize="md" />
      <div className="flex flex-col gap-2">
        <ElementComparisonRow>
          <span>{team1.team_country ?? '-'}</span>
          <span className="text-tiny uppercase">COUNTRY</span>
          <span>{team2.team_country ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{team1.established ?? '-'}</span>
          <span className="text-tiny uppercase">ESTABLISHED YEAR</span>
          <span>{team2.established ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{team1.stadium_name ?? '-'}</span>
          <span className="text-tiny uppercase">HOME STADIUM</span>
          <span>{team2.stadium_name ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{team1.head_coach ?? '-'}</span>
          <span className="text-tiny uppercase">HEAD COACH</span>
          <span>{team2.head_coach ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{team1.dom_league_name ?? '-'}</span>
          <span className="text-tiny uppercase">DOM COMPETITION NAME</span>
          <span>{team2.dom_league_name ?? '-'}</span>
        </ElementComparisonRow>
        <ElementComparisonRow>
          <span>{team1.total_value ? toShortNumber(team1.total_value) : '-'}</span>
          <span className="text-tiny uppercase">TOTAL VALUE</span>
          <span>{team2.total_value ? toShortNumber(team2.total_value) : '-'}</span>
        </ElementComparisonRow>
      </div>
    </div>
  );
});

export default TeamComparisonElement;
