import { memo } from 'react';
import { getDefaultColor } from '../../../../helpers';
import TeamHeader from '../Team/TeamHeader';
import { ReportTypeHeader, TeamGeneralData } from '../interfaces';

interface TeamComparisonHeaderProps extends ReportTypeHeader {
  team1: TeamGeneralData;
  team2: TeamGeneralData;
}

function TeamComparisonHeader({
  team1,
  team2,
  textSize = 'sm',
  iconSize = 'md',
  narrower = false
}: TeamComparisonHeaderProps) {
  team1.color ??= getDefaultColor(0);
  team2.color ??= getDefaultColor(1);
  return (
    <div className="grid grid-cols-2 gap-3">
      <TeamHeader textSize={textSize} iconSize={iconSize} narrower={narrower} team={team1} />
      <TeamHeader textSize={textSize} iconSize={iconSize} narrower={narrower} team={team2} />
    </div>
  );
}

export default memo(TeamComparisonHeader);
