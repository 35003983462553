import { QueryClient } from '@tanstack/react-query';
import {
  getGetProjectProjectIdDashboardDatasourcesCountQueryOptions,
  getGetProjectProjectIdDashboardIterationStatsQueryOptions
} from 'lib/dashboard/dashboard';
import { getGetProjectProjectIdDataTableQueryOptions } from 'lib/data-table/data-table';
import { getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions } from 'lib/datasource-configuration/datasource-configuration';
import { getGetDatasourceDatasourceIdQueryOptions, getGetDatasourceQueryOptions } from 'lib/datasource/datasource';
import { ProjectGetSchema } from 'lib/model';
import { getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions } from 'lib/project-configuration/project-configuration';
import { getGetProjectProjectIdRoleQueryOptions } from 'lib/project-role/project-role';
import { getGetProjectProjectIdQueryOptions, getGetProjectQueryOptions } from 'lib/project/project';
import { getGetReportQueryOptions, getGetReportReportIdQueryOptions } from 'lib/report/report';
import { getGetProjectProjectIdSubscriptionQueryOptions } from 'lib/subscription/subscription';
import { getGetUserQueryOptions } from 'lib/user-account/user-account';
import { defer, LoaderFunctionArgs } from 'react-router-dom';

async function getProject(queryClient: QueryClient) {
  let project: ProjectGetSchema;

  // Check if the project is already set in local storage
  const projectId = localStorage.getItem('active_project');
  if (projectId) {
    const projectData = queryClient.getQueryData<ProjectGetSchema>(['project', projectId]);
    if (projectData) {
      return projectData;
    }
  }

  const userQueryOptions = getGetUserQueryOptions({
    query: { queryKey: ['user'], staleTime: Infinity }
  });
  const user = await queryClient.ensureQueryData(userQueryOptions);

  if (user.user_projects && user.user_projects.length > 0) {
    const projectId = user.user_projects![0].project!;

    const projectQueryOptions = getGetProjectProjectIdQueryOptions(projectId, {
      query: { queryKey: ['project', projectId], enabled: !!projectId, staleTime: Infinity }
    });
    project = await queryClient.ensureQueryData(projectQueryOptions)!;
  } else if (user.is_admin) {
    const projectsQueryOptions = getGetProjectQueryOptions(
      { unverified: false },
      {
        query: { queryKey: ['projects'], staleTime: Infinity }
      }
    );
    const projects = await queryClient.ensureQueryData(projectsQueryOptions);
    project = projects.objects![0]!;
  } else {
    throw new Error('User does not have access to any projects');
  }

  return project;
}

export const baseLoader = (queryClient: QueryClient) => async () => {
  const project = await getProject(queryClient);

  const datasourceCountQueryOptions = getGetProjectProjectIdDashboardDatasourcesCountQueryOptions(project.id!, {
    query: { queryKey: ['project', project.id, 'data-sources', 'count'], enabled: !!project.id, staleTime: Infinity }
  });
  const datasourceCountPromise = queryClient.ensureQueryData(datasourceCountQueryOptions);

  const dataTablesQueryOptions = getGetProjectProjectIdDataTableQueryOptions(project.id!, {
    query: {
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });
  const dataTablesPromise = queryClient.ensureQueryData(dataTablesQueryOptions);

  const subscriptionQueryOptions = getGetProjectProjectIdSubscriptionQueryOptions(
    project.id!,
    {},
    { query: { queryKey: ['project', project.id!, 'subscription'], staleTime: Infinity } }
  );
  const subscriptionPromise = queryClient.ensureQueryData(subscriptionQueryOptions);

  const projectRolesQueryOptions = getGetProjectProjectIdRoleQueryOptions(project.id!, {
    query: { queryKey: ['project', project.id!, 'roles'], retry: false, staleTime: Infinity }
  });
  const projectRolesPromise = queryClient.ensureQueryData(projectRolesQueryOptions);

  const initialData = Promise.all([
    datasourceCountPromise,
    dataTablesPromise,
    subscriptionPromise,
    projectRolesPromise
  ]);

  return defer({ initialData: initialData });
};

export const dashboardLoader = (queryClient: QueryClient) => async () => {
  const dataSourcesOptions = getGetDatasourceQueryOptions(undefined, {
    query: { queryKey: ['dataSources'], staleTime: Infinity }
  });
  const dataSourcesPromise = queryClient.ensureQueryData(dataSourcesOptions);

  const project = await getProject(queryClient);

  if (project.project_configuration_latest) {
    const today = new Date();
    const initialLastDate = new Date(today.getTime() - today.getTimezoneOffset() * 60000).toISOString().split('T')[0];
    const iterationStatsQueryOptions = getGetProjectProjectIdDashboardIterationStatsQueryOptions(
      project.id!,
      {
        last_date: initialLastDate
      },
      {
        query: {
          queryKey: ['project', project.id, 'iteration-stats', initialLastDate],
          staleTime: 1000 * 60 * 15 // 15 minutes
        }
      }
    );
    queryClient.prefetchQuery(iterationStatsQueryOptions);
  }

  return null;
};

export const dataSourcesLoader = (queryClient: QueryClient) => async () => {
  const project = await getProject(queryClient);
  const projectConfigurationId = project.project_configuration_latest;

  if (projectConfigurationId) {
    const dataSourcesQueryOptions = getGetDatasourceQueryOptions(undefined, {
      query: { queryKey: ['dataSources'], staleTime: Infinity }
    });
    queryClient.prefetchQuery(dataSourcesQueryOptions);

    const dataSourceConfigurationsQueryOptions =
      getGetProjectConfigurationProjectConfigurationIdDatasourceConfigurationQueryOptions(projectConfigurationId!, {
        query: {
          queryKey: [
            'project',
            project.id,
            'configuration',
            project.project_configuration_latest,
            'datasource-configuration'
          ],
          staleTime: Infinity
        }
      });
    queryClient.prefetchQuery(dataSourceConfigurationsQueryOptions);

    const projectConfigurationQueryOptions = getGetProjectProjectIdConfigurationProjectConfigurationIdQueryOptions(
      project.id!,
      projectConfigurationId!,
      { nested: true },
      {
        query: {
          queryKey: ['project', project.id, 'configuration', project.project_configuration_latest],
          staleTime: Infinity,
          refetchOnReconnect: false
        }
      }
    );
    queryClient.prefetchQuery(projectConfigurationQueryOptions);
  }

  return null;
};

export const dataSourceDetailsLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs<string>) => {
    const queryOptions = getGetDatasourceDatasourceIdQueryOptions(params.dataSourceId!, {
      query: {
        queryKey: ['dataSourcesById', params.dataSourceId],
        staleTime: Infinity
      }
    });
    queryClient.ensureQueryData(queryOptions);
    return null;
  };

export const dataTablesLoader = (queryClient: QueryClient) => async () => {
  // ENDPOINTS CALLED IN BASE LOADER
  // const project = await getProject(queryClient);

  // const queryOptions = getGetProjectProjectIdDataTableQueryOptions(project.id!, {
  //   query: {
  //     queryKey: ['dataTables'],
  //     staleTime: Infinity
  //   }
  // });
  // queryClient.ensureQueryData(queryOptions);
  return null;
};

export const reportsLoader = (queryClient: QueryClient) => async () => {
  const reportQueryOptions = getGetReportQueryOptions(
    {},
    {
      query: {
        queryKey: ['reports'],
        staleTime: 1000 * 60 * 15 // 15 minutes
      }
    }
  );
  queryClient.prefetchQuery(reportQueryOptions);
  return null;
};

export const reportLoader =
  (queryClient: QueryClient) =>
  async ({ params }: LoaderFunctionArgs<string>) => {
    const reportQueryOptions = getGetReportReportIdQueryOptions(params.reportId!, {
      query: {
        queryKey: ['reports', params.reportId!]
      }
    });
    queryClient.prefetchQuery(reportQueryOptions);
    return null;
  };
