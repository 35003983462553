import { SubscriptionGetSchema } from 'lib/model';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';
import { useMemo } from 'react';

export function useProjectSubscriptionExpiration(projectId: string) {
  const { data: projectSubscription } = useGetProjectProjectIdSubscription<SubscriptionGetSchema>(
    projectId!,
    {},
    {
      query: {
        queryKey: ['project', projectId, 'subscription'],
        staleTime: Infinity,
        enabled: !!projectId,
        refetchOnWindowFocus: false
      }
    }
  );

  const { expirationDate, expirationString } = useMemo(() => {
    if (!projectSubscription || !projectSubscription.subscription_end) {
      return { expirationDate: null, expirationString: 'N/A' };
    }

    const date = new Date(projectSubscription.subscription_end);
    return {
      expirationDate: date,
      expirationString: date.toLocaleString(undefined, {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    };
  }, [projectSubscription]);

  return { expirationDate, expirationString };
}
