import SvgJersey from './SvgJersey';
import SvgTeam from './SvgTeam';

interface SvgIconProps extends React.SVGProps<SVGGElement> {
  type: 'team' | 'player';
  top: number;
  left: number;
  color: string;
  number: number;
  blackNumber?: boolean;
}

const SvgIcon = ({ type, top, left, color, number, blackNumber = false, ...props }: SvgIconProps) => {
  switch (type) {
    case 'player':
      return <SvgJersey top={top} left={left} color={color} number={number} blackNumber={blackNumber} {...props} />;
    case 'team':
      return <SvgTeam top={top} left={left} color={color} number={number} {...props} />;
    default:
      return null;
  }
};

export default SvgIcon;
