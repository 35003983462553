import { ReportElementSchema, ReportElementTemplateSchema, ReportNestedSchema } from 'lib/model';
import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useMemo } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { Season, TeamsBySeason, ValueOption } from 'utils/interfaces';
import { metricToMetricOption, seasonToValueOption, stringToValueOption, teamToValueOption } from 'utils/mappings';
import { getTeamsFromSeason } from '../../helpers';
import { RadarChartElementFormValues } from '../interfaces';

interface Props {
  control: Control<RadarChartElementFormValues>;
  template: ReportElementTemplateSchema;
  report?: ReportNestedSchema;
  element?: ReportElementSchema;
}

const RadarChartLeagueForm = ({ control, template }: Props) => {
  const formState = useFormState({ control });
  const activeMetrics = useWatch({ control: control, name: 'metrics_teams_match' });
  const seasons = useWatch({ control: control, name: 'seasons' });

  const attributeTypeOptions = useMemo(
    () => template.attribute_choices!.attribute_type?.map(stringToValueOption) || [],
    [template.attribute_choices]
  );

  // Get metric options from metric seasons and active season
  const metricOptions = useMemo(() => {
    return template.attribute_choices!.metrics_teams_match?.map(metricToMetricOption) || [];
  }, [template.attribute_choices]);

  const aggregationOptions = useMemo(() => {
    let seasons = new Set<string>(template.attribute_choices!.seasons.map((season: Season) => season.season));
    let recommendedAggregations = new Set<string>(template.attribute_choices!.aggregation_metric);
    for (const metric of activeMetrics) {
      if (metric.seasons_list) {
        seasons = seasons.intersection(new Set(metric.seasons_list));
      }

      if (metric.recommended_aggregations) {
        recommendedAggregations = recommendedAggregations.intersection(new Set(metric.recommended_aggregations));
      }
    }
    return {
      seasons: [...seasons],
      recommendedAggregations: [...recommendedAggregations]
    };
  }, [activeMetrics]);

  const seasonOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.seasons.filter((season: Season) => aggregationOptions.seasons.includes(season.season))
      .map(seasonToValueOption);
  }, [template.attribute_choices, aggregationOptions.seasons]);

  const methodOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.aggregation_metric.filter((method: string) =>
        aggregationOptions.recommendedAggregations.includes(method)
      )
      .map(stringToValueOption);
  }, [template.attribute_choices, aggregationOptions.recommendedAggregations]);

  const teamOptions: ValueOption[] = useMemo(() => {
    const teamsBySeason: TeamsBySeason[] = template.attribute_choices!.team_by_season ?? [];
    const activeSeasons = seasons.map((season) => season.id) as string[];
    const teams = getTeamsFromSeason(teamsBySeason, activeSeasons) ?? [];
    return teams.map(teamToValueOption);
  }, [template.attribute_choices, seasons]);

  return (
    <>
      <SelectInput
        formProps={{
          control: control,
          name: 'attribute_type',
          rules: { required: 'Main attribute is required!' }
        }}
        label={'Main attribute'}
        disabled={true}
        options={attributeTypeOptions}
        error={formState.errors.attribute_type}
      />
      {/* Team metrics */}
      <SelectInput
        formProps={{
          control: control,
          name: `metrics_teams_match`,
          rules: {
            validate: {
              minLength: (values: ValueOption[]) => values?.length > 2 || 'At least three metrics are required!'
            }
          }
        }}
        multiple
        key={`metrics_teams_match`}
        label={'Metrics'}
        placeholder={'Choose metrics to aggregate'}
        options={metricOptions}
        error={formState.errors.metrics_teams_match}
      />
      {/* Aggregation metric */}
      <SelectInput
        formProps={{
          control: control,
          name: 'aggregation_metric',
          rules: { required: 'Aggregate method is required!' }
        }}
        label={'Aggregate method'}
        options={methodOptions}
        error={formState.errors.aggregation_metric}
      />
      <div className="-my-2 text-xs font-semibold">FILTERS</div>
      {/* Select seasons */}
      <SelectInput
        formProps={{
          control: control,
          name: 'seasons'
        }}
        multiple
        label={'Seasons'}
        options={seasonOptions}
        error={formState.errors.seasons}
      />
      {/* Select team */}
      <SelectInput
        formProps={{
          control: control,
          name: 'teams'
        }}
        multiple
        label={'Teams'}
        options={teamOptions}
        error={formState.errors.teams}
      />
    </>
  );
};

export default RadarChartLeagueForm;
