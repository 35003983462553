import Back from 'assets/arrow-left.svg?react';
import Time from 'assets/time.svg?react';
import useAuth from 'contexts/auth/authContext';
import { ReportNestedSchema } from 'lib/model';
import { useGetReportReportIdSchedule } from 'lib/report/report';
import Button from 'modules/common/Button';
import CircleImage from 'modules/common/CircleImage';
import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { snakeCaseToWords } from 'utils/helpers';
import ReportPageDropdown from './ReportPageDropdown';
import SendReportDialog from './dialogs/sendReportDialog/SendReportDialog';
import ShareReportDialog from './dialogs/shareReportDialog/ShareReportDialog';
import TransferReportDialog from './dialogs/transferReportDialog/TransferReportDialog';

interface ReportHeaderProps {
  report?: ReportNestedSchema;
}

const ReportHeader = memo(function ReportHeader({ report }: ReportHeaderProps) {
  const [showSendReportDialog, setShowSendReportDialog] = useState<boolean>(false);
  const [showShareReportDialog, setShowShareReportDialog] = useState<boolean>(false);
  const [showTransferReportDialog, setShowTransferReportDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const { user, isAdmin, isOwner } = useAuth();
  const { data: schedule, isPending: isPendingSchedule } = useGetReportReportIdSchedule(report?.id ?? '', {
    query: {
      queryKey: ['reportSchedule', report?.id],
      enabled: !!report?.id,
      staleTime: Infinity,
      retry: false,
      throwOnError: false,
      refetchOnWindowFocus: false
    }
  });

  function handleBack() {
    navigate('/reports');
  }

  function handleShowTransferReportDialog() {
    setShowTransferReportDialog(true);
  }

  function handleShowSendReportDialog() {
    setShowSendReportDialog(true);
  }

  function handleShowShareReportDialog() {
    setShowShareReportDialog(true);
  }

  return (
    <header className="sticky top-0 z-40 bg-gray-100 pb-8 pt-10">
      <div className="flex flex-wrap items-center justify-between gap-6 max-md:flex-col max-md:items-start">
        <div className="flex items-center gap-2">
          <Back className="size-6 shrink-0 cursor-pointer fill-gray-950" onClick={handleBack} />
          {report ? (
            <div className="flex flex-col">
              <div className="text-tiny font-medium text-gray-950">
                {snakeCaseToWords(report?.report_type ?? '')} Report
              </div>
              <span className="text-lg font-semibold text-gray-950">{report?.name}</span>
            </div>
          ) : (
            <div className="flex w-full flex-col gap-1">
              <div className="h-4 w-16 animate-pulse rounded-md bg-gray-200" />
              <div className="h-6 w-1/2 animate-pulse rounded-md bg-gray-200" />
            </div>
          )}
        </div>
        <div className="flex flex-wrap items-center justify-end gap-8 self-end">
          {report ? (
            <div className="flex items-center gap-2 px-3 py-2.5">
              <span className="text-tiny font-medium text-gray-500">OWNER:</span>
              <CircleImage
                image={report.owner_image_path}
                text={
                  report?.owner_first_name!.charAt(0).toUpperCase() + report?.owner_last_name!.charAt(0).toUpperCase()
                }
                size="size-5"
              />
              <span className="text-xs font-medium">
                {report?.owner_first_name} {report?.owner_last_name}
              </span>
            </div>
          ) : (
            <div className="h-6 w-36 animate-pulse rounded-md bg-gray-200" />
          )}
          {(report?.owner_user === user.id || isOwner || isAdmin) && (
            <div className="flex flex-wrap items-center gap-4">
              {report?.owner_user === user.id && (
                <Button
                  size="lg"
                  variant="secondary"
                  isFullWidth={false}
                  onClick={handleShowTransferReportDialog}
                  disabled={!report}
                >
                  Transfer
                </Button>
              )}
              <Button
                size="lg"
                variant="secondary"
                isFullWidth={false}
                onClick={handleShowShareReportDialog}
                disabled={!report}
              >
                Share
              </Button>
              <Button
                size="lg"
                variant="secondary"
                isFullWidth={false}
                onClick={handleShowSendReportDialog}
                disabled={isPendingSchedule}
              >
                <span>Send</span>
                {schedule && schedule.frequency !== 'once' && (
                  <Time width={20} height={20} style={{ fill: ' #F97316' }} />
                )}
              </Button>
              <ReportPageDropdown report={report} />
            </div>
          )}
        </div>
      </div>
      {report && showTransferReportDialog && (
        <TransferReportDialog report={report} open={showTransferReportDialog} setOpen={setShowTransferReportDialog} />
      )}
      {report && showSendReportDialog && !isPendingSchedule && (
        <SendReportDialog
          schedule={schedule}
          report={report}
          open={showSendReportDialog}
          setOpen={setShowSendReportDialog}
        />
      )}
      {report && showShareReportDialog && (
        <ShareReportDialog report={report} open={showShareReportDialog} setOpen={setShowShareReportDialog} />
      )}
    </header>
  );
});
export default ReportHeader;
