import { ReportElementSchema, ReportNestedSchema } from 'lib/model';
import { memo } from 'react';
import { getDefaultColor, getDefaultTeamColor } from '../../../../helpers';
import { PlayerGeneralData, ScoutPlayerData, TeamGeneralData } from '../../reportCards/interfaces';
import { ScatterChartPoint } from './interfaces';

interface ScatterChartLegendProps {
  report: ReportNestedSchema;
  element: ReportElementSchema;
}

function ScatterChartLegend({ report, element }: ScatterChartLegendProps) {
  if (!report.general_data) return null;

  switch (report.report_type) {
    case 'team_comparison': {
      const generalData = report.general_data as { team1: TeamGeneralData; team2: TeamGeneralData };
      return (
        <>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor: generalData.team1.color ?? getDefaultTeamColor(report, generalData.team1.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.team1.team_name}</span>
          </span>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor: generalData.team2.color ?? getDefaultTeamColor(report, generalData.team2.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.team2.team_name}</span>
          </span>
        </>
      );
    }
    case 'team': {
      const generalData = report.general_data as TeamGeneralData;
      return (
        <span className="flex items-center gap-3">
          <span
            className="size-3 rounded-full"
            style={{ backgroundColor: generalData.color ?? getDefaultTeamColor(report, generalData.team_id) }}
          />
          <span className="text-xs font-semibold">{generalData.team_name}</span>
        </span>
      );
    }
    case 'player_comparison': {
      const generalData = report.general_data as { player1: PlayerGeneralData; player2: PlayerGeneralData };
      return (
        <>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor:
                  generalData.player1.team_color ?? getDefaultTeamColor(report, generalData.player1.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.player1.player_name}</span>
          </span>
          <span className="flex items-center gap-3">
            <span
              className="size-3 rounded-full"
              style={{
                backgroundColor:
                  generalData.player2.team_color ?? getDefaultTeamColor(report, generalData.player2.team_id)
              }}
            />
            <span className="text-xs font-semibold">{generalData.player2.player_name}</span>
          </span>
        </>
      );
    }
    case 'scout': {
      const generalData = report.general_data as {
        players_filtered: ScoutPlayerData[];
        players_manual: ScoutPlayerData[];
      };
      return (
        <div className="flex flex-wrap gap-3">
          {[...generalData.players_manual, ...generalData.players_filtered].map((player: ScoutPlayerData, index) => (
            <span key={player.player_id} className="flex items-center gap-3">
              <span
                className="size-3 rounded-full"
                style={{ backgroundColor: player.team_color ?? getDefaultColor(index) }}
              />
              <span className="text-xs font-semibold">{player.player_name}</span>
            </span>
          ))}
        </div>
      );
    }
    case 'league': {
      const elementData = (element.entity_data?.result as ScatterChartPoint[]) ?? [];
      const teamPoints = elementData?.reduce(
        (acc, event) => {
          if (!acc[event.team_id]) {
            acc[event.team_id] = event;
          }
          return acc;
        },
        {} as { [key: string]: ScatterChartPoint }
      );
      const teams = Object.values(teamPoints).map((team) => {
        const teamColor = team.team_color;
        if (!teamColor || teamColor === '') {
          return { ...team, team_color: getDefaultColor(team.team_id) };
        }
        return team;
      });
      return (
        <div className="flex flex-wrap gap-2">
          {teams.map((team: ScatterChartPoint, index) => (
            <span key={team.player_id} className="flex items-center gap-3">
              <span className="size-3 rounded-full" style={{ backgroundColor: team.team_color as string }} />
              <span className="text-xs font-semibold">{team.team_name}</span>
            </span>
          ))}
        </div>
      );
    }
    default:
      return null;
  }
}

export default memo(ScatterChartLegend);
