/* eslint-disable @typescript-eslint/no-explicit-any */
import { Circle } from '@visx/shape';
import { CircleProps } from '@visx/shape/lib/shapes/Circle';
import { AddSVGProps } from '@visx/shape/lib/types';
import { isLightColor } from 'utils/helpers';
import { LineChartPoint } from '../interfaces';

interface LinePointProps extends AddSVGProps<CircleProps, SVGCircleElement> {
  color: string;
  point: LineChartPoint;

  handleMouseEnter: (point: LineChartPoint) => () => void;
  handleMouseLeave: () => void;
}

const ChartPoint = ({ point, color, cx, cy, handleMouseEnter, handleMouseLeave }: LinePointProps) => {
  return isLightColor(color) ? (
    <>
      <Circle cx={cx} cy={cy} r={5} strokeWidth={2} stroke="#f3f4f6" />
      <Circle
        cx={cx}
        cy={cy}
        r={4}
        strokeWidth={1}
        stroke="#9ca3af"
        fill={color}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleMouseEnter(point)}
      />
    </>
  ) : (
    <Circle
      cx={cx}
      cy={cy}
      r={4}
      strokeWidth={2}
      stroke="#f3f4f6"
      fill={color}
      onMouseLeave={handleMouseLeave}
      onMouseEnter={handleMouseEnter(point)}
    />
  );
};

export default ChartPoint;
