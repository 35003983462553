import { memo } from 'react';
import { twJoin, twMerge } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';
import TeamShield from '../../TeamShield';

import { getDefaultColor } from '../../../../helpers';
import { ReportTypeHeader, TeamGeneralData } from '../interfaces';

interface TeamHeaderProps extends ReportTypeHeader {
  team: TeamGeneralData;
}

function TeamHeader({ team, textSize = 'sm', iconSize = 'md', narrower = false }: TeamHeaderProps) {
  const color = team.color ?? getDefaultColor(0);
  const isLight = isLightColor(color);
  return (
    <div
      className={twMerge(
        'flex flex-col items-center gap-3 rounded-xl border px-3',
        narrower ? 'py-3' : 'py-6',
        isLight ? 'border-gray-300' : 'border-transparent'
      )}
      style={{ backgroundColor: color }}
    >
      <TeamShield color={color} size={iconSize} shadow />
      <span
        className={twJoin(
          'text-center text-lg font-semibold',
          textSize === 'tiny' && 'text-tiny',
          textSize === 'sm' && 'text-sm',
          textSize === 'lg' && 'text-lg',
          isLight ? 'text-gray-900' : 'text-white'
        )}
      >
        {team.team_name}
      </span>
    </div>
  );
}

export default memo(TeamHeader);
