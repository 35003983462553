import { ReportSchema } from 'lib/model';
import MatchHeader from 'modules/reports/reportPage/components/reportCards/Match/MatchHeader';
import PlayerHeader from 'modules/reports/reportPage/components/reportCards/Player/PlayerHeader';
import PlayerComparisonHeader from 'modules/reports/reportPage/components/reportCards/PlayerComparison/PlayerComparisonHeader';
import ScoutHeader from 'modules/reports/reportPage/components/reportCards/Scout/ScoutHeader';
import TeamHeader from 'modules/reports/reportPage/components/reportCards/Team/TeamHeader';
import TeamComparisonHeader from 'modules/reports/reportPage/components/reportCards/TeamComparison/TeamComparisonHeader';
import { memo } from 'react';
import { NavLink } from 'react-router-dom';
import {
  LeagueGeneralData,
  MatchGeneralData,
  PlayerComparisonGeneralData,
  PlayerGeneralData,
  ScoutGeneralData,
  TeamComparisonGeneralData,
  TeamGeneralData
} from '../../../reportPage/components/reportCards/interfaces';
import ReportIcon from '../ReportIcon';
import SharedUsersIcons from '../SharedUsersIcons';
import LeagueHeader from 'modules/reports/reportPage/components/reportCards/League/LeagueHeader';

interface ReportCardProps {
  report: ReportSchema;
}

function ReportCard({ report }: ReportCardProps) {
  let reportHeader;
  if (!report.general_data) {
    reportHeader = null;
  } else {
    switch (report.report_type) {
      case 'match': {
        const data = report.general_data as MatchGeneralData;
        reportHeader = <MatchHeader textSize="tiny" narrower match={data} />;
        break;
      }
      case 'player': {
        const data = report.general_data as PlayerGeneralData;
        reportHeader = <PlayerHeader textSize="tiny" iconSize="md" narrower player={data} />;
        break;
      }
      case 'player_comparison': {
        const data = report.general_data as PlayerComparisonGeneralData;
        reportHeader = (
          <PlayerComparisonHeader
            textSize="tiny"
            iconSize="md"
            narrower
            player1={data.player1}
            player2={data.player2}
          />
        );
        break;
      }
      case 'team': {
        const data = report.general_data as TeamGeneralData;
        reportHeader = <TeamHeader textSize="tiny" iconSize="md" narrower team={data} />;
        break;
      }
      case 'team_comparison': {
        const data = report.general_data as TeamComparisonGeneralData;
        reportHeader = (
          <TeamComparisonHeader textSize="tiny" iconSize="md" narrower team1={data.team1} team2={data.team2} />
        );
        break;
      }
      case 'scout': {
        const data = report.general_data as ScoutGeneralData;
        reportHeader = (
          <ScoutHeader
            narrower
            textSize="tiny"
            iconSize="md"
            manualPlayers={data.players_manual}
            filteredPlayers={data.players_filtered}
          />
        );
        break;
      }
      case 'league': {
        const data = report.general_data as LeagueGeneralData;
        reportHeader = <LeagueHeader textSize="tiny" iconSize="md" narrower league={data} />;
        break;
      }
      default:
        reportHeader = null;
        break;
    }
  }
  const lastEdited = report.last_edited_timestamp ? new Date(report.last_edited_timestamp) : undefined;

  return (
    <NavLink
      className="flex flex-col justify-between gap-4 rounded-xl bg-white p-4 transition-transform duration-200 hover:-translate-y-1 hover:shadow-card"
      to={`/reports/${report.id}`}
    >
      <div className="flex flex-col gap-4">
        {reportHeader}
        <div className="flex items-center gap-2">
          <ReportIcon reportType={report.report_type!} size="sm" />
          <span className="truncate text-xs font-semibold" title={report.name}>
            {report.name}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-between gap-6">
        <div className="flex flex-col gap-1">
          <span className="text-micro font-medium">LAST EDITED</span>
          {lastEdited ? (
            <div className="flex items-center gap-2">
              <span className="text-tiny font-semibold">{lastEdited.toLocaleDateString()}</span>
              <span className="h-2.5 w-px bg-gray-300" />
              <span className="text-tiny font-semibold">{lastEdited.toLocaleTimeString()}</span>
            </div>
          ) : (
            <span className="text-tiny font-semibold">-</span>
          )}
        </div>
        <SharedUsersIcons users={report.users ?? []} />
      </div>
    </NavLink>
  );
}

export default memo(ReportCard);
