import MatchReport from 'assets/event-ball.svg?react';
import TeamReport from 'assets/football-shield-outline.svg?react';
import TeamComparisonReport from 'assets/football-shield2-outline.svg?react';
import PlayerReport from 'assets/jersey.svg?react';
import PlayerComparisonReport from 'assets/jersey2.svg?react';
import ScoutReport from 'assets/spy.svg?react';
import LeagueReport from 'assets/trophy.svg?react';
import { ReportSchemaReportType } from 'lib/model';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';

interface ReportIconProps {
  reportType: ReportSchemaReportType;
  size?: 'sm' | 'md';
}

function ReportIcon({ reportType, size = 'md' }: ReportIconProps) {
  const className = twJoin('shrink-0 fill-gray-700', size === 'md' ? 'size-5' : 'size-4')
  switch (reportType) {
    case 'match':
      return <MatchReport className={className} />;
    case 'player':
      return <PlayerReport className={className} />;
    case 'player_comparison':
      return <PlayerComparisonReport className={className} />;
    case 'team':
      return <TeamReport className={className} />;
    case 'team_comparison':
      return <TeamComparisonReport className={className} />;
    case 'scout':
      return <ScoutReport className={className} />;
    case 'league':
      return <LeagueReport className={className} />;
    default:
      return null;
  }
}

export default memo(ReportIcon);
