import League from 'assets/trophy.svg?react';
import { memo } from 'react';
import { twJoin } from 'tailwind-merge';
import { isLightColor } from 'utils/helpers';

interface LeagueIconProps {
  color: string;
  size?: 'sm' | 'md' | 'lg';
  shadow?: boolean;
}

function LeagueIcon({ color, shadow = false, size = 'md' }: LeagueIconProps) {
  const isLight = isLightColor(color);
  let svgSize = 24;
  switch (size) {
    case 'md':
      svgSize = 32;
      break;
    case 'lg':
      svgSize = 44;
      break;
  }

  return <League width={svgSize} height={svgSize} className={twJoin('fill-white', shadow && 'drop-shadow-card')} />;
}

export default memo(LeagueIcon);
