import SelectInput from 'modules/common/Form/Select/SelectInput';
import { useMemo } from 'react';
import { useFormState, useWatch } from 'react-hook-form';
import { Metric, Season, TeamsBySeason, ValueOption } from 'utils/interfaces';
import { metricToMetricOption, seasonToValueOption, stringToValueOption, teamToValueOption } from 'utils/mappings';
import { getTeamsFromSeason } from '../../helpers';
import { ElementFormComponentProps, ScatterChartElementFormValues } from '../interfaces';

const ScatterLeagueForm = ({ control, template }: ElementFormComponentProps<ScatterChartElementFormValues>) => {
  const formState = useFormState({ control });

  const xAxis = useWatch({ control: control, name: 'x_axis_match' });
  const yAxis = useWatch({ control: control, name: 'y_axis_match' });
  const seasons = useWatch({ control: control, name: 'seasons' });

  // Get metrics from metric seasons and active seasons
  function getMetrics(metrics: Metric[], otherAxis: string | number | undefined): ValueOption[] {
    const options = metrics.map(metricToMetricOption);
    return options.filter((option) => option.id !== otherAxis);
  }

  const activeMetrics = useMemo(() => {
    const metrics = [];
    if (xAxis) {
      metrics.push(xAxis);
    }
    if (yAxis) {
      metrics.push(yAxis);
    }
    return metrics;
  }, [xAxis, yAxis]);

  const aggregationOptions = useMemo(() => {
    let seasons = new Set<string>(template.attribute_choices!.seasons.map((season: Season) => season.season));
    let recommendedAggregations = new Set<string>(template.attribute_choices!.aggregation_metric);
    for (const metric of activeMetrics) {
      if (metric.seasons_list) {
        seasons = seasons.intersection(new Set(metric.seasons_list));
      }

      if (metric.recommended_aggregations) {
        recommendedAggregations = recommendedAggregations.intersection(new Set(metric.recommended_aggregations));
      }
    }
    return {
      seasons: [...seasons],
      recommendedAggregations: [...recommendedAggregations]
    };
  }, [activeMetrics]);

  const seasonOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.seasons.filter((season: Season) => aggregationOptions.seasons.includes(season.season))
      .map(seasonToValueOption);
  }, [template.attribute_choices, aggregationOptions.seasons]);

  const methodOptions: ValueOption[] = useMemo(() => {
    return template
      .attribute_choices!.aggregation_metric.filter((method: string) =>
        aggregationOptions.recommendedAggregations.includes(method)
      )
      .map(stringToValueOption);
  }, [template.attribute_choices, aggregationOptions.recommendedAggregations]);

  const xAxisMatchOptions = useMemo(() => {
    const metrics = template.attribute_choices!.x_axis_match || [];
    return getMetrics(metrics, yAxis?.id);
  }, [template.attribute_choices, yAxis?.id]);

  const yAxisMatchOptions = useMemo(() => {
    const metrics = template.attribute_choices!.y_axis_match || [];
    return getMetrics(metrics, xAxis?.id);
  }, [template.attribute_choices, xAxis?.id]);

  const teamOptions: ValueOption[] = useMemo(() => {
    const teamsBySeason: TeamsBySeason[] = template.attribute_choices!.team_by_season ?? [];
    const activeSeasons = seasons.map((season) => season.id) as string[];
    const teams = getTeamsFromSeason(teamsBySeason, activeSeasons) ?? [];
    return teams.map(teamToValueOption);
  }, [template.attribute_choices, seasons]);

  return (
    <>
      <SelectInput
        formProps={{
          control: control,
          name: 'x_axis_match',
          rules: { required: 'X Axis is required!' }
        }}
        label={'X Axis'}
        options={xAxisMatchOptions}
        error={formState.errors.x_axis_match}
      />
      <SelectInput
        formProps={{
          control: control,
          name: 'y_axis_match',
          rules: { required: 'Y Axis is required!' }
        }}
        label={'Y Axis'}
        options={yAxisMatchOptions}
        error={formState.errors.y_axis_match}
      />
      {/* Aggregation metric */}
      <SelectInput
        formProps={{
          control: control,
          name: 'aggregation_metric',
          rules: { required: 'Aggregate method is required!' }
        }}
        label={'Aggregate method'}
        options={methodOptions}
        error={formState.errors.aggregation_metric}
      />
      <div className="-my-2 text-xs font-semibold">FILTERS</div>
      {/* Select seasons */}
      <SelectInput
        formProps={{
          control: control,
          name: 'seasons'
        }}
        multiple
        label={'Seasons'}
        options={seasonOptions}
        error={formState.errors.seasons}
      />
      {/* Select team */}
      {/* <SelectInput
        formProps={{
          control: control,
          name: 'teams'
        }}
        multiple
        label={'Teams'}
        options={teamOptions}
        error={formState.errors.teams}
      /> */}
    </>
  );
};

export default ScatterLeagueForm;
