import ChevronDown from 'assets/chevron-down.svg?react';
import ChevronUp from 'assets/chevron-up.svg?react';
import Divider from 'modules/common/Divider';

import { isLightColor } from 'utils/helpers';
import Jersey from '../../../Jersey';
import { MatchTimePoint, PositionMapPlayerData } from '../interfaces';

interface MarkProps {
  data: MatchTimePoint;
  onChangeTime: (time: number) => void;
  maxTime?: number;
}

const renderPlayerData = (player: PositionMapPlayerData) => {
  const color = player.team_color!;
  const isLight = isLightColor(color);
  return (
    <div key={player.player_id} className="text-center">
      <Jersey color={color} shirt_number={player.shirt_number} size={'md'} shadow outline={isLight} />
      {player.player_name}
    </div>
  );
};

const Mark = ({ data, onChangeTime, maxTime = 90 }: MarkProps) => {
  const { match_time, subbed_in, subbed_out } = data;
  // TODO: Fix clipping issues
  // Description overlaps with nearby marks
  return (
    <div
      key={match_time}
      style={{ left: `${(match_time / maxTime) * 100.0}%` }}
      className="group absolute z-20 flex -translate-x-1/2 cursor-pointer flex-col items-center justify-center"
      onClick={() => onChangeTime(match_time)}
    >
      <div className="mb-2 size-4 cursor-pointer rounded-full border-2 border-black bg-white" />
      <div className="hidden flex-col items-center justify-center gap-1 rounded-2xl border border-gray-300 bg-white p-2 text-sm group-hover:flex">
        <div className="font-semibold">Match time: {match_time}'</div>
        {subbed_in && subbed_in.length > 0 && (
          <div className="flex w-full items-center gap-2">
            <ChevronUp className="size-8 fill-green-600" />
            <div className="flex w-full justify-around gap-2">{subbed_in.map(renderPlayerData)}</div>
          </div>
        )}
        <Divider />
        {subbed_out && subbed_out.length > 0 && (
          <div className="flex w-full items-center gap-2">
            <ChevronDown className="size-8 fill-red-600" />
            <div className="flex w-full justify-around gap-2">{subbed_out.map(renderPlayerData)}</div>
          </div>
        )}
      </div>
    </div>
  );
};

interface MatchTimeSliderProps {
  timePoints: { [key: number]: MatchTimePoint };
  currentTime: number;
  onChangeTime: (time: number) => void;
}

const MatchTimeSlider = ({ timePoints, currentTime, onChangeTime }: MatchTimeSliderProps) => {
  const minutes = Object.keys(timePoints).map((time) => Number(time));
  const maxTime = Math.max(...minutes, 90);

  return (
    <div className="relative">
      <div className={'absolute h-2 w-full translate-y-1 rounded-full bg-brand-800'} />
      <Mark key={0} data={{ match_time: 0, subbed_in: [], subbed_out: [] }} onChangeTime={onChangeTime} />
      {minutes.map((minute) => (
        <Mark key={minute} data={timePoints[minute]} onChangeTime={onChangeTime} maxTime={maxTime} />
      ))}
      <div
        className="absolute z-30 size-5 -translate-x-1/2 -translate-y-0.5 cursor-pointer rounded-full border-4 border-brand-800 bg-brand-800"
        style={{ left: `${(currentTime / maxTime) * 100.0}%` }}
      />
    </div>
  );
};

export default MatchTimeSlider;
