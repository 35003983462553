import { ReportElementTemplateSchemaAttributeChoices, ReportNestedSchema } from 'lib/model';
import { Competition, MatchDetailed, Season, Team, TeamsBySeason, ValueOption } from 'utils/interfaces';
import { matchToValueOption, stringToValueOption } from 'utils/mappings';
import { ElementFormValues } from './elementForms/interfaces';

export function transformAttributeValues(
  data: ElementFormValues,
  attributeChoices?: ReportElementTemplateSchemaAttributeChoices
) {
  const attributeValues: Record<string, string[] | number[] | boolean[] | undefined> = {};
  for (const [key, value] of Object.entries(data)) {
    if (!attributeChoices?.[key]) {
      continue;
    }

    let attributeValue: string[] | number[] | boolean[] | undefined;
    if (typeof value === 'boolean') {
      attributeValue = [value];
    } else if (!!value && Array.isArray(value)) {
      attributeValue = (value as ValueOption[]).map((option) => option.value ?? option.id) as number[] | string[];
    } else if (!!value) {
      attributeValue = [value.value ?? value.id] as number[] | string[];
    }
    if ((key === 'seasons' || key === 'seasons_comparison') && attributeValue?.length === 0) {
      const allSeasons = attributeChoices[key].map((option: Season) => option.season);
      attributeValue = allSeasons;
    }
    if ((key === 'competitions' || key === 'competitions_comparison') && attributeValue?.length === 0) {
      const allCompetitions = attributeChoices[key].map((option: Competition) => option.competition_id);
      attributeValue = allCompetitions;
    }
    if (attributeValue) {
      attributeValues[key] = attributeValue;
    }
  }

  return attributeValues;
}

// Get metric options from metric seasons and active seasons
export function getMetricsListFromSeasons(
  seasons: ValueOption[],
  metricsBySeason: { [key: string]: string[] }
): string[] {
  if (!metricsBySeason) {
    return [];
  }
  const includedSeasons: { [key: string]: boolean } = {};
  let allSeasons = false;
  if (!seasons || seasons.length === 0) {
    // Include all seasons
    allSeasons = true;
  } else {
    for (const season of seasons) {
      includedSeasons[season.id] = true;
    }
  }

  let metrics: string[] = [];
  for (const [key, values] of Object.entries(metricsBySeason)) {
    if (allSeasons || includedSeasons[key]) {
      metrics = metrics.concat(values);
    }
  }
  return [...new Set(metrics)];
}

export function getMetricOptionsFromSeasons(
  seasons: ValueOption[],
  metricsBySeason: { [key: string]: string[] }
): ValueOption[] {
  if (!metricsBySeason) {
    return [];
  }
  const metrics = getMetricsListFromSeasons(seasons, metricsBySeason);
  return metrics.map(stringToValueOption);
}

export function getFilteredMatches(
  matches: MatchDetailed[],
  seasons: ValueOption[] | null,
  competitions: ValueOption[] | null
): MatchDetailed[] {
  if (seasons && seasons.length) {
    matches = matches.filter((match: MatchDetailed) => seasons.find((season) => season.id === match.season));
  }
  if (competitions && competitions.length) {
    matches = matches.filter((match: MatchDetailed) =>
      competitions.find((competition) => competition.id === match.competition_id)
    );
  }
  return matches;
}
export function getFilteredMatchesOptions(
  matches: MatchDetailed[],
  seasons: ValueOption[] | null,
  competitions: ValueOption[] | null
): ValueOption[] {
  const newMatches = getFilteredMatches(matches, seasons, competitions);
  return newMatches.map(matchToValueOption);
}

export function showAggregateOptions(report: ReportNestedSchema): boolean {
  const type = report.report_type;
  switch (type) {
    case 'team_comparison':
    case 'player_comparison':
    case 'scout':
    case 'team':
    case 'player':
      return true;
    case 'match':
    case 'league':
      return false;
    default:
      return false;
  }
}

export function getTeamsFromSeason(teamsBySeason: TeamsBySeason[], seasons?: string[]): Team[] {
  const activeSeasonTeams = teamsBySeason
    .filter((teams) => {
      if (!seasons || seasons.length === 0) return true;
      return seasons.find((season) => season === teams.season);
    })
    .map((teams) => teams.teams)
    .flat();

  const uniqueTeams: { [key: number]: Team } = {};
  activeSeasonTeams.reduce((acc, team) => {
    if (!acc[team.team_id]) {
      acc[team.team_id] = team;
    }
    return acc;
  }, uniqueTeams);
  const teams = Object.values(uniqueTeams);
  return teams ?? [];
}
  