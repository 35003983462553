import useActiveProject from 'contexts/project/projectContext';
import { useGetProjectProjectIdDataTable } from 'lib/data-table/data-table';
import { SubscriptionGetSchema } from 'lib/model';
import { useGetProjectProjectIdRole } from 'lib/project-role/project-role';
import { useGetProjectProjectIdUser } from 'lib/project-user/project-user';
import { useGetSubscriptionTierSubscriptionTierId } from 'lib/subscription-tier/subscription-tier';
import { useGetProjectProjectIdSubscription } from 'lib/subscription/subscription';

export default function useInitialDataLoad() {
  const { project } = useActiveProject();

  const { isFetching: isDataTablesFethcing } = useGetProjectProjectIdDataTable(project.id!, {
    query: {
      enabled: !!project.id,
      queryKey: ['project', project.id, 'data-tables'],
      staleTime: Infinity
    }
  });

  const { data: activeTier, isPending: isFetchingTier } = useGetProjectProjectIdSubscription<SubscriptionGetSchema>(
    project.id!,
    {},
    { query: { queryKey: ['project', project.id!, 'subscription'], staleTime: Infinity, enabled: !!project.id } }
  );


  const subscription_tier = activeTier?.subscription_tier;
  const { isPending: isFetchingTierData } = useGetSubscriptionTierSubscriptionTierId(subscription_tier!, {
    query: {
      queryKey: ['subscription', subscription_tier],
      staleTime: Infinity,
      enabled: !!subscription_tier
    }
  });

  const {
    isFetching: isProjectUsersFetching,
    data: projectUsers,
    isError: isProjectUsersError
  } = useGetProjectProjectIdUser(project.id!, {
    query: {
      enabled: !!project.id,
      queryKey: ['project', project.id, 'users'],
      retry: false,
      refetchOnWindowFocus: false
    }
  });

  // Load for other elements?
  useGetProjectProjectIdRole(project.id!, {
    query: { queryKey: ['project', project.id, 'roles'], retry: false, staleTime: Infinity, enabled: !!project.id }
  });

  const isLoading = isProjectUsersFetching || isDataTablesFethcing || isFetchingTier || isFetchingTierData;
  const shouldInviteUsers = !isProjectUsersError && projectUsers?.objects?.length === 1;

  return { project, isLoading, shouldInviteUsers, activeTier };
}
